/*! CSS Used from: https://stackpath.bootstrapcdn.com/bootstrap/4.5.2/css/bootstrap.min.css */
*,
::after,
::before {
	box-sizing: border-box;
}
section {
	display: block;
}
a {
	color: #007bff;
	text-decoration: none;
	background-color: transparent;
}
a:hover {
	color: #0056b3;
	text-decoration: underline;
}
a:not([href]):not([class]) {
	color: inherit;
	text-decoration: none;
}
a:not([href]):not([class]):hover {
	color: inherit;
	text-decoration: none;
}
img {
	vertical-align: middle;
	border-style: none;
}
.btn {
	display: inline-block;
	font-weight: 400;
	color: #212529;
	text-align: center;
	vertical-align: middle;
	-webkit-user-select: none;
	-moz-user-select: none;
	-ms-user-select: none;
	user-select: none;
	background-color: transparent;
	border: 1px solid transparent;
	padding: 0.375rem 0.75rem;
	font-size: 1rem;
	line-height: 1.5;
	border-radius: 0.25rem;
	transition: color 0.15s ease-in-out, background-color 0.15s ease-in-out,
		border-color 0.15s ease-in-out, box-shadow 0.15s ease-in-out;
}
@media (prefers-reduced-motion: reduce) {
	.btn {
		transition: none;
	}
}
.btn:hover {
	color: #212529;
	text-decoration: none;
}
.btn:focus {
	outline: 0;
	box-shadow: 0 0 0 0.2rem rgba(0, 123, 255, 0.25);
}
.btn:disabled {
	opacity: 0.65;
}
.btn:not(:disabled):not(.disabled) {
	cursor: pointer;
}
.card {
	position: relative;
	display: -ms-flexbox;
	display: flex;
	-ms-flex-direction: column;
	flex-direction: column;
	min-width: 0;
	word-wrap: break-word;
	background-color: #fff;
	background-clip: border-box;
	border: 1px solid rgba(0, 0, 0, 0.125);
	border-radius: 0.25rem;
}
.d-flex {
	display: -ms-flexbox !important;
	display: flex !important;
}
.justify-content-center {
	-ms-flex-pack: center !important;
	justify-content: center !important;
}
.float-right {
	float: right !important;
}
.mr-2 {
	margin-right: 0.5rem !important;
}
.mt-4 {
	margin-top: 1.5rem !important;
}
@media print {
	*,
	::after,
	::before {
		text-shadow: none !important;
		box-shadow: none !important;
	}
	a:not(.btn) {
		text-decoration: underline;
	}
	img {
		page-break-inside: avoid;
	}
}
/*! CSS Used from: Embedded */
* {
	font-family: "Lato", sans-serif;
}
a {
	text-decoration: none;
	cursor: pointer;
}
a:hover {
	text-decoration: none !important;
	color: #d31148;
}
a:active {
	color: #d31148;
}
a:focus {
	color: #d31148;
	outline: none;
}
.btn {
	-webkit-appearance: none;
	border-radius: 7.5px !important;
}
.btn-main,
.btn-main:hover {
	background-color: #d31148 !important;
	color: #fff !important;
}

.directory
	.network-map-cards
	.map-card-wrapper
	.my-masonry-grid
	.my-masonry-grid_column
	section {
	background: transparent !important;
}
.preview-market-map .map-card-wrapper {
	margin: 0 10px !important;
}
.preview-market-map .btn-main {
	width: 200px !important;
}
.preview-market-map .network-map-cards {
	padding: 20px;
}
.preview-market-map .network-map-cards .network-map-groups {
	margin: 0 !important;
}
.preview-market-map .network-map-cards .my-masonry-grid {
	margin: 0 -20px;
}
.preview-market-map .network-map-cards .my-masonry-grid .map-card {
	padding-bottom: 0 !important;
}
.directory .network-map-main .map-card-wrapper {
	margin: 0;
}
a {
	text-decoration: none !important;
	color: #5d656d;
}
*:focus {
	outline: none !important;
}
/*! CSS Used from: Embedded */
*,
::after,
::before {
	box-sizing: border-box;
}
section {
	display: block;
}
a {
	color: #007bff;
	text-decoration: none;
	background-color: transparent;
}
a:hover {
	color: #0056b3;
	text-decoration: underline;
}
a:not([href]):not([class]) {
	color: inherit;
	text-decoration: none;
}
a:not([href]):not([class]):hover {
	color: inherit;
	text-decoration: none;
}
img {
	vertical-align: middle;
	border-style: none;
}
.btn {
	display: inline-block;
	font-weight: 400;
	color: #212529;
	text-align: center;
	vertical-align: middle;
	-webkit-user-select: none;
	user-select: none;
	background-color: transparent;
	border: 1px solid transparent;
	padding: 0.375rem 0.75rem;
	font-size: 1rem;
	line-height: 1.5;
	border-radius: 0.25rem;
	transition: color 0.15s ease-in-out, background-color 0.15s ease-in-out,
		border-color 0.15s ease-in-out, box-shadow 0.15s ease-in-out;
}
@media (prefers-reduced-motion: reduce) {
	.btn {
		transition: none;
	}
}
.btn:hover {
	color: #212529;
	text-decoration: none;
}
.btn:focus {
	outline: 0;
	box-shadow: 0 0 0 0.2rem rgba(0, 123, 255, 0.25);
}
.btn:disabled {
	opacity: 0.65;
}
.btn:not(:disabled):not(.disabled) {
	cursor: pointer;
}
.card {
	position: relative;
	display: flex;
	flex-direction: column;
	min-width: 0;
	word-wrap: break-word;
	background-color: #fff;
	background-clip: border-box;
	border: 1px solid rgba(0, 0, 0, 0.125);
	border-radius: 0.25rem;
}
.d-flex {
	display: flex !important;
}
.justify-content-center {
	justify-content: center !important;
}
.float-right {
	float: right !important;
}
.mr-2 {
	margin-right: 0.5rem !important;
}
.mt-4 {
	margin-top: 1.5rem !important;
}
@media print {
	*,
	::after,
	::before {
		text-shadow: none !important;
		box-shadow: none !important;
	}
	a:not(.btn) {
		text-decoration: underline;
	}
	img {
		page-break-inside: avoid;
	}
}
/*! CSS Used from: Embedded */
*,
::after,
::before {
	box-sizing: border-box;
}
a {
	color: #007bff;
	text-decoration: none;
	background-color: transparent;
}
a:hover {
	color: #0056b3;
	text-decoration: underline;
}
img {
	vertical-align: middle;
	border-style: none;
}
@media print {
	*,
	::after,
	::before {
		text-shadow: none !important;
		box-shadow: none !important;
	}
	a:not(.btn) {
		text-decoration: underline;
	}
	img {
		page-break-inside: avoid;
	}
}
* {
	font-family: "Lato", sans-serif;
}
a {
	text-decoration: none;
	cursor: pointer;
}
a:hover {
	text-decoration: none !important;
	color: #d31148;
}
a:active {
	color: #d31148;
}
a:focus {
	color: #d31148;
	outline: none;
}
a {
	text-decoration: none !important;
	color: #5d656d;
}
*:focus {
	outline: none !important;
}
*,
::after,
::before {
	box-sizing: border-box;
}
a {
	color: #007bff;
	text-decoration: none;
	background-color: transparent;
}
a:hover {
	color: #0056b3;
	text-decoration: underline;
}
img {
	vertical-align: middle;
	border-style: none;
}
@media print {
	*,
	::after,
	::before {
		text-shadow: none !important;
		box-shadow: none !important;
	}
	a:not(.btn) {
		text-decoration: underline;
	}
	img {
		page-break-inside: avoid;
	}
}
.fas {
	-moz-osx-font-smoothing: grayscale;
	-webkit-font-smoothing: antialiased;
	display: inline-block;
	font-style: normal;
	font-variant: normal;
	text-rendering: auto;
	line-height: 1;
}
.fas {
	font-family: "Font Awesome 5 Free";
}
.fas {
	font-weight: 900;
}
* {
	font-family: "Lato", sans-serif;
	letter-spacing: 0.6px;
}
*:focus {
	outline: none;
}
a {
	cursor: pointer;
	color: #5d656d;
	text-decoration: none;
}
a:hover {
	color: #d31148;
	text-decoration: none;
}
.fas {
	font-family: "Font Awesome 5 Free" !important;
}
img {
	vertical-align: middle;
	border-style: none;
	border: 0;
	max-width: 100%;
}
*,
::after,
::before {
	box-sizing: border-box;
}
a {
	text-decoration: none;
	background-color: transparent;
}
a:hover {
	color: #d31148;
	text-decoration: underline;
}
img {
	vertical-align: middle;
	border-style: none;
}
@media print {
	*,
	::after,
	::before {
		text-shadow: none !important;
		box-shadow: none !important;
	}
	a:not(.btn) {
		text-decoration: underline;
	}
	img {
		page-break-inside: avoid;
	}
}
/*! CSS Used from: Embedded */
*,
::after,
::before {
	box-sizing: border-box;
}
a {
	color: #007bff;
	text-decoration: none;
	background-color: transparent;
}
a:hover {
	color: #0056b3;
	text-decoration: underline;
}
img {
	vertical-align: middle;
	border-style: none;
}
.mt-4 {
	margin-top: 1.5rem !important;
}
@media print {
	*,
	::after,
	::before {
		text-shadow: none !important;
		box-shadow: none !important;
	}
	a:not(.btn) {
		text-decoration: underline;
	}
	img {
		page-break-inside: avoid;
	}
}
* {
	font-family: "Lato", sans-serif;
}
a {
	text-decoration: none;
	cursor: pointer;
}
a:hover {
	text-decoration: none !important;
	color: #d31148;
}
a:active {
	color: #d31148;
}
a:focus {
	color: #d31148;
	outline: none;
}
a {
	text-decoration: none !important;
	color: #5d656d;
}
*:focus {
	outline: none !important;
}
*,
::after,
::before {
	box-sizing: border-box;
}
a {
	color: #007bff;
	text-decoration: none;
	background-color: transparent;
}
a:hover {
	color: #0056b3;
	text-decoration: underline;
}
img {
	vertical-align: middle;
	border-style: none;
}
.mt-4 {
	margin-top: 1.5rem !important;
}
@media print {
	*,
	::after,
	::before {
		text-shadow: none !important;
		box-shadow: none !important;
	}
	a:not(.btn) {
		text-decoration: underline;
	}
	img {
		page-break-inside: avoid;
	}
}
.fas {
	-moz-osx-font-smoothing: grayscale;
	-webkit-font-smoothing: antialiased;
	display: inline-block;
	font-style: normal;
	font-variant: normal;
	text-rendering: auto;
	line-height: 1;
}
.fas {
	font-family: "Font Awesome 5 Free";
}
.fas {
	font-weight: 900;
}
* {
	font-family: "Lato", sans-serif;
	letter-spacing: 0.6px;
}
*:focus {
	outline: none;
}
a {
	cursor: pointer;
	color: #5d656d;
	text-decoration: none;
}
a:hover {
	color: #d31148;
	text-decoration: none;
}
.fas {
	font-family: "Font Awesome 5 Free" !important;
}
img {
	vertical-align: middle;
	border-style: none;
	border: 0;
	max-width: 100%;
}
*,
::after,
::before {
	box-sizing: border-box;
}
a {
	text-decoration: none;
	background-color: transparent;
}
a:hover {
	color: #d31148;
	text-decoration: underline;
}
img {
	vertical-align: middle;
	border-style: none;
}
.mt-4 {
	margin-top: 1.5rem !important;
}
@media print {
	*,
	::after,
	::before {
		text-shadow: none !important;
		box-shadow: none !important;
	}
	a:not(.btn) {
		text-decoration: underline;
	}
	img {
		page-break-inside: avoid;
	}
}
*,
::after,
::before {
	box-sizing: border-box;
}
a {
	color: #007bff;
	text-decoration: none;
	background-color: transparent;
}
a:hover {
	color: #0056b3;
	text-decoration: underline;
}
img {
	vertical-align: middle;
	border-style: none;
}
@media print {
	*,
	::after,
	::before {
		text-shadow: none !important;
		box-shadow: none !important;
	}
	a:not(.btn) {
		text-decoration: underline;
	}
	img {
		page-break-inside: avoid;
	}
}
* {
	font-family: "Lato", sans-serif;
}
a {
	text-decoration: none;
	cursor: pointer;
}
a:hover {
	text-decoration: none !important;
	color: #d31148;
}
a:active {
	color: #d31148;
}
a:focus {
	color: #d31148;
	outline: none;
}
a {
	text-decoration: none !important;
	color: #5d656d;
}
*:focus {
	outline: none !important;
}
*,
::after,
::before {
	box-sizing: border-box;
}
a {
	color: #007bff;
	text-decoration: none;
	background-color: transparent;
}
a:hover {
	color: #0056b3;
	text-decoration: underline;
}
img {
	vertical-align: middle;
	border-style: none;
}
@media print {
	*,
	::after,
	::before {
		text-shadow: none !important;
		box-shadow: none !important;
	}
	a:not(.btn) {
		text-decoration: underline;
	}
	img {
		page-break-inside: avoid;
	}
}
.fas {
	-moz-osx-font-smoothing: grayscale;
	-webkit-font-smoothing: antialiased;
	display: inline-block;
	font-style: normal;
	font-variant: normal;
	text-rendering: auto;
	line-height: 1;
}
.fas {
	font-family: "Font Awesome 5 Free";
}
.fas {
	font-weight: 900;
}
* {
	font-family: "Lato", sans-serif;
	letter-spacing: 0.6px;
}
*:focus {
	outline: none;
}
a {
	cursor: pointer;
	color: #5d656d;
	text-decoration: none;
}
a:hover {
	color: #d31148;
	text-decoration: none;
}
.fas {
	font-family: "Font Awesome 5 Free" !important;
}
img {
	vertical-align: middle;
	border-style: none;
	border: 0;
	max-width: 100%;
}
*,
::after,
::before {
	box-sizing: border-box;
}
a {
	text-decoration: none;
	background-color: transparent;
}
a:hover {
	color: #d31148;
	text-decoration: underline;
}
img {
	vertical-align: middle;
	border-style: none;
}
@media print {
	*,
	::after,
	::before {
		text-shadow: none !important;
		box-shadow: none !important;
	}
	a:not(.btn) {
		text-decoration: underline;
	}
	img {
		page-break-inside: avoid;
	}
}
/*! CSS Used from: Embedded */
*,
::after,
::before {
	box-sizing: border-box;
}
section {
	display: block;
}
@media print {
	*,
	::after,
	::before {
		text-shadow: none !important;
		box-shadow: none !important;
	}
}
* {
	font-family: "Lato", sans-serif;
}
*:focus {
	outline: none !important;
}
*,
::after,
::before {
	box-sizing: border-box;
}
section {
	display: block;
}
@media print {
	*,
	::after,
	::before {
		text-shadow: none !important;
		box-shadow: none !important;
	}
}
*,
::after,
::before {
	box-sizing: border-box;
}
@media print {
	*,
	::after,
	::before {
		text-shadow: none !important;
		box-shadow: none !important;
	}
}
* {
	font-family: "Lato", sans-serif;
}
*:focus {
	outline: none !important;
}
*,
::after,
::before {
	box-sizing: border-box;
}
@media print {
	*,
	::after,
	::before {
		text-shadow: none !important;
		box-shadow: none !important;
	}
}
* {
	font-family: "Lato", sans-serif;
	letter-spacing: 0.6px;
}
*:focus {
	outline: none;
}
*,
::after,
::before {
	box-sizing: border-box;
}
@media print {
	*,
	::after,
	::before {
		text-shadow: none !important;
		box-shadow: none !important;
	}
}
*,
::after,
::before {
	box-sizing: border-box;
}
@media print {
	*,
	::after,
	::before {
		text-shadow: none !important;
		box-shadow: none !important;
	}
}
* {
	font-family: "Lato", sans-serif;
}
*:focus {
	outline: none !important;
}
*,
::after,
::before {
	box-sizing: border-box;
}
@media print {
	*,
	::after,
	::before {
		text-shadow: none !important;
		box-shadow: none !important;
	}
}
* {
	font-family: "Lato", sans-serif;
	letter-spacing: 0.6px;
}
*:focus {
	outline: none;
}
*,
::after,
::before {
	box-sizing: border-box;
}
@media print {
	*,
	::after,
	::before {
		text-shadow: none !important;
		box-shadow: none !important;
	}
}
*,
::after,
::before {
	box-sizing: border-box;
}
@media print {
	*,
	::after,
	::before {
		text-shadow: none !important;
		box-shadow: none !important;
	}
}
* {
	font-family: "Lato", sans-serif;
}
*:focus {
	outline: none !important;
}
*,
::after,
::before {
	box-sizing: border-box;
}
@media print {
	*,
	::after,
	::before {
		text-shadow: none !important;
		box-shadow: none !important;
	}
}
* {
	font-family: "Lato", sans-serif;
	letter-spacing: 0.6px;
}
*:focus {
	outline: none;
}
*,
::after,
::before {
	box-sizing: border-box;
}
@media print {
	*,
	::after,
	::before {
		text-shadow: none !important;
		box-shadow: none !important;
	}
}
* {
	font-family: "Lato", sans-serif;
	letter-spacing: 0.6px;
}
*:focus {
	outline: none;
}
section {
	margin: 0;
	padding: 15px 0;
	background: #f8f8f8;
}
.mid-width-info-box
	.info-box-container
	.info-box-body
	*:not(.map-dashboard-graph *) {
	font-size: 16px;
}
*,
::after,
::before {
	box-sizing: border-box;
}
section {
	display: block;
}
@media print {
	*,
	::after,
	::before {
		text-shadow: none !important;
		box-shadow: none !important;
	}
}
/*! CSS Used from: Embedded */
/*! @import https://use.fontawesome.com/releases/v5.8.1/css/all.css */
.fas {
	-moz-osx-font-smoothing: grayscale;
	-webkit-font-smoothing: antialiased;
	display: inline-block;
	font-style: normal;
	font-variant: normal;
	text-rendering: auto;
	line-height: 1;
}
.fa-arrow-circle-down:before {
	content: "\f0ab";
}
.fas {
	font-family: "Font Awesome 5 Free";
}
.fas {
	font-weight: 900;
}
/*! end @import */
/*! CSS Used from: Embedded */
.network-map-main .network-map-groups {
	margin: 0 25px;
	text-align: end;
}
.network-map-main .group-buttons {
	cursor: pointer;
	display: inline-block;
	font-weight: 400;
	text-align: center;
	vertical-align: middle;
	border: 1px solid transparent;
	padding: 0.375rem 0.75rem;
	font-size: 1rem;
	line-height: 1.5;
	border-radius: 7.5px;
	margin: 5px;
	min-width: 100px;
}
.network-map-main .group-buttons.active {
	background-color: #d31148;
	color: #ffffff !important;
}
.network-map-main .group-buttons.inactive {
	background-color: #ffffff;
	border: 1px solid #d31148;
	color: #d31148 !important;
}
.network-map-main .map-card-wrapper {
	margin: 20px;
	margin-top: -10px;
}
.network-map-main .my-masonry-grid {
	display: flex;
	margin: 0 -10px;
}
.network-map-main .map-card {
	page-break-inside: avoid;
	break-inside: avoid;
	text-align: center;
	padding: 20px 10px !important;
}
.network-map-main .map-box {
	box-shadow: 0 0 6px 0 rgba(0, 0, 0, 0.1);
	border-radius: 7px;
}
.network-map-main .card {
	background: #ffffff;
	position: relative;
	padding: 0 !important;
	height: 100%;
	display: flex;
	flex-direction: column;
	margin-bottom: 0;
}
.network-map-main .card__header {
	background: #f0f0f0;
	font-weight: 600;
	position: relative;
	color: #5d656d;
	padding: 12px 20px;
	font-size: 18px;
	line-height: 1.4;
	letter-spacing: normal;
	text-transform: none;
	text-align: left;
	border-top-right-radius: 7px;
	border-top-left-radius: 7px;
}
.network-map-main .card__title {
	overflow: hidden;
	display: flex;
	justify-content: space-between;
	text-transform: capitalize;
}
.network-map-main .card__content {
	background: #ffffff;
	flex: 1 1;
	display: flex;
	flex-direction: column;
	padding: 10px;
	border-bottom-left-radius: 7px;
	border-bottom-right-radius: 7px;
}
.network-map-main .map-card__items {
	display: flex;
	flex-wrap: wrap;
	justify-content: center;
}
.network-map-main .map-card__entity {
	display: inline-block;
	transition: all 1s;
	padding: 2px 5px 16px;
	color: #6c757d;
}
.network-map-main .entity-icon {
	height: 60px;
	width: 60px;
	margin: auto;
}
.network-map-main .entity-image {
	object-fit: contain;
	max-width: 100%;
	max-height: 100%;
	border-radius: 7px;
	border: 1px solid rgba(0, 0, 0, 0.125);
}
.network-map-main .entity-image-div {
	width: 100%;
	height: 100%;
	color: #5d656d;
	background-color: #f0f0f0;
	padding: 15px;
	border-radius: 7px;
	border: 1px solid rgba(0, 0, 0, 0.125);
	font-size: 23px !important;
	align-items: center;
	display: flex;
	justify-content: center;
}
.network-map-main .fadezoom {
	animation: anim-fadein-zoom 0.5s;
}
.network-map-main .card__more {
	text-align: center;
	padding-bottom: 10px;
	background-color: #ffffff;
	border-bottom-left-radius: 7px;
	border-bottom-right-radius: 7px;
}
.network-map-main .card__more a {
	color: #d54347 !important;
}
* {
	font-family: "Lato", sans-serif;
	letter-spacing: 0.6px;
}
*:focus {
	outline: none;
}
a {
	cursor: pointer;
	color: #5d656d;
	text-decoration: none;
}
a:hover {
	color: #d31148;
	text-decoration: none;
}
.btn {
	-webkit-appearance: none;
	border-radius: 7.5px;
}
.btn-main,
.btn-main:hover {
	background-color: #d31148;
	color: #fff !important;
}
.btn:focus {
	box-shadow: none;
}
.fas {
	font-family: "Font Awesome 5 Free" !important;
}
section {
	margin: 0;
	padding: 15px 0;
	background: #f8f8f8;
}
.mid-width-info-box
	.info-box-container
	.info-box-body
	*:not(.map-dashboard-graph *) {
	font-size: 16px;
}
/* .mid-width-info-box .info-box-container .info-box-body a:not(.page-link) {
	color: #d31148;
} */
img {
	vertical-align: middle;
	border-style: none;
	border: 0;
	max-width: 100%;
}


/* === startup/ice hover component === */
.popover {
	max-width: 290px !important;
  }
  .popover .popover-header,
  .popover .popover-body {
	background: #FFF;
	border-radius: 0.3em;
  }
  .popover .popover-header {
	border-bottom: none;
	padding: 0.75rem;
	padding-bottom: 0;
  }
  .popover .popover-header h4 {
	font-size: 16px;
	margin-bottom: 0;
	font-weight: bold;
  }
  .popover .popover-header .popover-img {
	height: 35px;
	width: 35px;
	border-radius: 7px;
	background: white;
	margin-right: 10px;
	display: flex;
	align-items: center;
	flex-shrink: 0;
	border: 1px solid #eee;
	overflow: hidden;
  }
  .popover .popover-header .popover-img img {
	width: 100%;
	height: auto;
  }
  .popover .popover-body p:not(:last-child) {
	margin-bottom: 4px;
  }
  .popover .popover-body p > span {
	font-weight: bold;
	color: #D31148;
  }
  .popover .popover-body div > p:first-child {
	display: -webkit-box;
	overflow: hidden;
	text-overflow: ellipsis;
	-webkit-line-clamp: 3;
	-webkit-box-orient: vertical;
  }
  .popover .popover-body hr {
	margin-top: 0.75rem;
	margin-bottom: 0.75rem;
  }
  .popover .normalized-text {
	color: #212529 !important;
	font-weight: 100 !important;
  }
  .popover .paywall-text-blur {
	filter: blur(3px);
	opacity: 0.8;
  }

  .filters .btns-container .dropdown .dropdown-menu .dropdown-item i:not(.fa-spin) {
	margin-right: 6px;
  }
  .filters .btns-container .dropdown .dropdown-menu .dropdown-item i.fa-spin {
	margin-left: 6px;
  }

/*! CSS Used fontfaces */
@font-face {
	font-family: "Lato";
	font-style: italic;
	font-weight: 400;
	font-display: swap;
	src: url(https://fonts.gstatic.com/s/lato/v24/S6u8w4BMUTPHjxsAUi-qJCY.woff2)
		format("woff2");
	unicode-range: U+0100-02AF, U+0304, U+0308, U+0329, U+1E00-1E9F, U+1EF2-1EFF,
		U+2020, U+20A0-20AB, U+20AD-20C0, U+2113, U+2C60-2C7F, U+A720-A7FF;
}
@font-face {
	font-family: "Lato";
	font-style: italic;
	font-weight: 400;
	font-display: swap;
	src: url(https://fonts.gstatic.com/s/lato/v24/S6u8w4BMUTPHjxsAXC-q.woff2)
		format("woff2");
	unicode-range: U+0000-00FF, U+0131, U+0152-0153, U+02BB-02BC, U+02C6, U+02DA,
		U+02DC, U+0304, U+0308, U+0329, U+2000-206F, U+2074, U+20AC, U+2122,
		U+2191, U+2193, U+2212, U+2215, U+FEFF, U+FFFD;
}
@font-face {
	font-family: "Lato";
	font-style: italic;
	font-weight: 700;
	font-display: swap;
	src: url(https://fonts.gstatic.com/s/lato/v24/S6u_w4BMUTPHjxsI5wq_FQft1dw.woff2)
		format("woff2");
	unicode-range: U+0100-02AF, U+0304, U+0308, U+0329, U+1E00-1E9F, U+1EF2-1EFF,
		U+2020, U+20A0-20AB, U+20AD-20C0, U+2113, U+2C60-2C7F, U+A720-A7FF;
}
@font-face {
	font-family: "Lato";
	font-style: italic;
	font-weight: 700;
	font-display: swap;
	src: url(https://fonts.gstatic.com/s/lato/v24/S6u_w4BMUTPHjxsI5wq_Gwft.woff2)
		format("woff2");
	unicode-range: U+0000-00FF, U+0131, U+0152-0153, U+02BB-02BC, U+02C6, U+02DA,
		U+02DC, U+0304, U+0308, U+0329, U+2000-206F, U+2074, U+20AC, U+2122,
		U+2191, U+2193, U+2212, U+2215, U+FEFF, U+FFFD;
}
@font-face {
	font-family: "Lato";
	font-style: normal;
	font-weight: 400;
	font-display: swap;
	src: url(https://fonts.gstatic.com/s/lato/v24/S6uyw4BMUTPHjxAwXjeu.woff2)
		format("woff2");
	unicode-range: U+0100-02AF, U+0304, U+0308, U+0329, U+1E00-1E9F, U+1EF2-1EFF,
		U+2020, U+20A0-20AB, U+20AD-20C0, U+2113, U+2C60-2C7F, U+A720-A7FF;
}
@font-face {
	font-family: "Lato";
	font-style: normal;
	font-weight: 400;
	font-display: swap;
	src: url(https://fonts.gstatic.com/s/lato/v24/S6uyw4BMUTPHjx4wXg.woff2)
		format("woff2");
	unicode-range: U+0000-00FF, U+0131, U+0152-0153, U+02BB-02BC, U+02C6, U+02DA,
		U+02DC, U+0304, U+0308, U+0329, U+2000-206F, U+2074, U+20AC, U+2122,
		U+2191, U+2193, U+2212, U+2215, U+FEFF, U+FFFD;
}
@font-face {
	font-family: "Lato";
	font-style: normal;
	font-weight: 700;
	font-display: swap;
	src: url(https://fonts.gstatic.com/s/lato/v24/S6u9w4BMUTPHh6UVSwaPGR_p.woff2)
		format("woff2");
	unicode-range: U+0100-02AF, U+0304, U+0308, U+0329, U+1E00-1E9F, U+1EF2-1EFF,
		U+2020, U+20A0-20AB, U+20AD-20C0, U+2113, U+2C60-2C7F, U+A720-A7FF;
}
@font-face {
	font-family: "Lato";
	font-style: normal;
	font-weight: 700;
	font-display: swap;
	src: url(https://fonts.gstatic.com/s/lato/v24/S6u9w4BMUTPHh6UVSwiPGQ.woff2)
		format("woff2");
	unicode-range: U+0000-00FF, U+0131, U+0152-0153, U+02BB-02BC, U+02C6, U+02DA,
		U+02DC, U+0304, U+0308, U+0329, U+2000-206F, U+2074, U+20AC, U+2122,
		U+2191, U+2193, U+2212, U+2215, U+FEFF, U+FFFD;
}
@font-face {
	font-family: "Lato";
	font-style: normal;
	font-weight: 900;
	font-display: swap;
	src: url(https://fonts.gstatic.com/s/lato/v24/S6u9w4BMUTPHh50XSwaPGR_p.woff2)
		format("woff2");
	unicode-range: U+0100-02AF, U+0304, U+0308, U+0329, U+1E00-1E9F, U+1EF2-1EFF,
		U+2020, U+20A0-20AB, U+20AD-20C0, U+2113, U+2C60-2C7F, U+A720-A7FF;
}
@font-face {
	font-family: "Lato";
	font-style: normal;
	font-weight: 900;
	font-display: swap;
	src: url(https://fonts.gstatic.com/s/lato/v24/S6u9w4BMUTPHh50XSwiPGQ.woff2)
		format("woff2");
	unicode-range: U+0000-00FF, U+0131, U+0152-0153, U+02BB-02BC, U+02C6, U+02DA,
		U+02DC, U+0304, U+0308, U+0329, U+2000-206F, U+2074, U+20AC, U+2122,
		U+2191, U+2193, U+2212, U+2215, U+FEFF, U+FFFD;
}
@font-face {
	font-family: "Lato";
	font-style: italic;
	font-weight: 400;
	font-display: swap;
	src: url(https://fonts.gstatic.com/s/lato/v24/S6u8w4BMUTPHjxsAUi-qJCY.woff2)
		format("woff2");
	unicode-range: U+0100-02AF, U+0304, U+0308, U+0329, U+1E00-1E9F, U+1EF2-1EFF,
		U+2020, U+20A0-20AB, U+20AD-20C0, U+2113, U+2C60-2C7F, U+A720-A7FF;
}
@font-face {
	font-family: "Lato";
	font-style: italic;
	font-weight: 400;
	font-display: swap;
	src: url(https://fonts.gstatic.com/s/lato/v24/S6u8w4BMUTPHjxsAXC-q.woff2)
		format("woff2");
	unicode-range: U+0000-00FF, U+0131, U+0152-0153, U+02BB-02BC, U+02C6, U+02DA,
		U+02DC, U+0304, U+0308, U+0329, U+2000-206F, U+2074, U+20AC, U+2122,
		U+2191, U+2193, U+2212, U+2215, U+FEFF, U+FFFD;
}
@font-face {
	font-family: "Lato";
	font-style: italic;
	font-weight: 700;
	font-display: swap;
	src: url(https://fonts.gstatic.com/s/lato/v24/S6u_w4BMUTPHjxsI5wq_FQft1dw.woff2)
		format("woff2");
	unicode-range: U+0100-02AF, U+0304, U+0308, U+0329, U+1E00-1E9F, U+1EF2-1EFF,
		U+2020, U+20A0-20AB, U+20AD-20C0, U+2113, U+2C60-2C7F, U+A720-A7FF;
}
@font-face {
	font-family: "Lato";
	font-style: italic;
	font-weight: 700;
	font-display: swap;
	src: url(https://fonts.gstatic.com/s/lato/v24/S6u_w4BMUTPHjxsI5wq_Gwft.woff2)
		format("woff2");
	unicode-range: U+0000-00FF, U+0131, U+0152-0153, U+02BB-02BC, U+02C6, U+02DA,
		U+02DC, U+0304, U+0308, U+0329, U+2000-206F, U+2074, U+20AC, U+2122,
		U+2191, U+2193, U+2212, U+2215, U+FEFF, U+FFFD;
}
@font-face {
	font-family: "Lato";
	font-style: normal;
	font-weight: 400;
	font-display: swap;
	src: url(https://fonts.gstatic.com/s/lato/v24/S6uyw4BMUTPHjxAwXjeu.woff2)
		format("woff2");
	unicode-range: U+0100-02AF, U+0304, U+0308, U+0329, U+1E00-1E9F, U+1EF2-1EFF,
		U+2020, U+20A0-20AB, U+20AD-20C0, U+2113, U+2C60-2C7F, U+A720-A7FF;
}
@font-face {
	font-family: "Lato";
	font-style: normal;
	font-weight: 400;
	font-display: swap;
	src: url(https://fonts.gstatic.com/s/lato/v24/S6uyw4BMUTPHjx4wXg.woff2)
		format("woff2");
	unicode-range: U+0000-00FF, U+0131, U+0152-0153, U+02BB-02BC, U+02C6, U+02DA,
		U+02DC, U+0304, U+0308, U+0329, U+2000-206F, U+2074, U+20AC, U+2122,
		U+2191, U+2193, U+2212, U+2215, U+FEFF, U+FFFD;
}
@font-face {
	font-family: "Lato";
	font-style: normal;
	font-weight: 700;
	font-display: swap;
	src: url(https://fonts.gstatic.com/s/lato/v24/S6u9w4BMUTPHh6UVSwaPGR_p.woff2)
		format("woff2");
	unicode-range: U+0100-02AF, U+0304, U+0308, U+0329, U+1E00-1E9F, U+1EF2-1EFF,
		U+2020, U+20A0-20AB, U+20AD-20C0, U+2113, U+2C60-2C7F, U+A720-A7FF;
}
@font-face {
	font-family: "Lato";
	font-style: normal;
	font-weight: 700;
	font-display: swap;
	src: url(https://fonts.gstatic.com/s/lato/v24/S6u9w4BMUTPHh6UVSwiPGQ.woff2)
		format("woff2");
	unicode-range: U+0000-00FF, U+0131, U+0152-0153, U+02BB-02BC, U+02C6, U+02DA,
		U+02DC, U+0304, U+0308, U+0329, U+2000-206F, U+2074, U+20AC, U+2122,
		U+2191, U+2193, U+2212, U+2215, U+FEFF, U+FFFD;
}
@font-face {
	font-family: "Lato";
	font-style: normal;
	font-weight: 900;
	font-display: swap;
	src: url(https://fonts.gstatic.com/s/lato/v24/S6u9w4BMUTPHh50XSwaPGR_p.woff2)
		format("woff2");
	unicode-range: U+0100-02AF, U+0304, U+0308, U+0329, U+1E00-1E9F, U+1EF2-1EFF,
		U+2020, U+20A0-20AB, U+20AD-20C0, U+2113, U+2C60-2C7F, U+A720-A7FF;
}
@font-face {
	font-family: "Lato";
	font-style: normal;
	font-weight: 900;
	font-display: swap;
	src: url(https://fonts.gstatic.com/s/lato/v24/S6u9w4BMUTPHh50XSwiPGQ.woff2)
		format("woff2");
	unicode-range: U+0000-00FF, U+0131, U+0152-0153, U+02BB-02BC, U+02C6, U+02DA,
		U+02DC, U+0304, U+0308, U+0329, U+2000-206F, U+2074, U+20AC, U+2122,
		U+2191, U+2193, U+2212, U+2215, U+FEFF, U+FFFD;
}
@font-face {
	font-family: "Lato";
	font-style: italic;
	font-weight: 400;
	font-display: swap;
	src: url(https://fonts.gstatic.com/s/lato/v24/S6u8w4BMUTPHjxsAUi-qJCY.woff2)
		format("woff2");
	unicode-range: U+0100-02AF, U+0304, U+0308, U+0329, U+1E00-1E9F, U+1EF2-1EFF,
		U+2020, U+20A0-20AB, U+20AD-20C0, U+2113, U+2C60-2C7F, U+A720-A7FF;
}
@font-face {
	font-family: "Lato";
	font-style: italic;
	font-weight: 400;
	font-display: swap;
	src: url(https://fonts.gstatic.com/s/lato/v24/S6u8w4BMUTPHjxsAXC-q.woff2)
		format("woff2");
	unicode-range: U+0000-00FF, U+0131, U+0152-0153, U+02BB-02BC, U+02C6, U+02DA,
		U+02DC, U+0304, U+0308, U+0329, U+2000-206F, U+2074, U+20AC, U+2122,
		U+2191, U+2193, U+2212, U+2215, U+FEFF, U+FFFD;
}
@font-face {
	font-family: "Lato";
	font-style: italic;
	font-weight: 700;
	font-display: swap;
	src: url(https://fonts.gstatic.com/s/lato/v24/S6u_w4BMUTPHjxsI5wq_FQft1dw.woff2)
		format("woff2");
	unicode-range: U+0100-02AF, U+0304, U+0308, U+0329, U+1E00-1E9F, U+1EF2-1EFF,
		U+2020, U+20A0-20AB, U+20AD-20C0, U+2113, U+2C60-2C7F, U+A720-A7FF;
}
@font-face {
	font-family: "Lato";
	font-style: italic;
	font-weight: 700;
	font-display: swap;
	src: url(https://fonts.gstatic.com/s/lato/v24/S6u_w4BMUTPHjxsI5wq_Gwft.woff2)
		format("woff2");
	unicode-range: U+0000-00FF, U+0131, U+0152-0153, U+02BB-02BC, U+02C6, U+02DA,
		U+02DC, U+0304, U+0308, U+0329, U+2000-206F, U+2074, U+20AC, U+2122,
		U+2191, U+2193, U+2212, U+2215, U+FEFF, U+FFFD;
}
@font-face {
	font-family: "Lato";
	font-style: normal;
	font-weight: 400;
	font-display: swap;
	src: url(https://fonts.gstatic.com/s/lato/v24/S6uyw4BMUTPHjxAwXjeu.woff2)
		format("woff2");
	unicode-range: U+0100-02AF, U+0304, U+0308, U+0329, U+1E00-1E9F, U+1EF2-1EFF,
		U+2020, U+20A0-20AB, U+20AD-20C0, U+2113, U+2C60-2C7F, U+A720-A7FF;
}
@font-face {
	font-family: "Lato";
	font-style: normal;
	font-weight: 400;
	font-display: swap;
	src: url(https://fonts.gstatic.com/s/lato/v24/S6uyw4BMUTPHjx4wXg.woff2)
		format("woff2");
	unicode-range: U+0000-00FF, U+0131, U+0152-0153, U+02BB-02BC, U+02C6, U+02DA,
		U+02DC, U+0304, U+0308, U+0329, U+2000-206F, U+2074, U+20AC, U+2122,
		U+2191, U+2193, U+2212, U+2215, U+FEFF, U+FFFD;
}
@font-face {
	font-family: "Lato";
	font-style: normal;
	font-weight: 700;
	font-display: swap;
	src: url(https://fonts.gstatic.com/s/lato/v24/S6u9w4BMUTPHh6UVSwaPGR_p.woff2)
		format("woff2");
	unicode-range: U+0100-02AF, U+0304, U+0308, U+0329, U+1E00-1E9F, U+1EF2-1EFF,
		U+2020, U+20A0-20AB, U+20AD-20C0, U+2113, U+2C60-2C7F, U+A720-A7FF;
}
@font-face {
	font-family: "Lato";
	font-style: normal;
	font-weight: 700;
	font-display: swap;
	src: url(https://fonts.gstatic.com/s/lato/v24/S6u9w4BMUTPHh6UVSwiPGQ.woff2)
		format("woff2");
	unicode-range: U+0000-00FF, U+0131, U+0152-0153, U+02BB-02BC, U+02C6, U+02DA,
		U+02DC, U+0304, U+0308, U+0329, U+2000-206F, U+2074, U+20AC, U+2122,
		U+2191, U+2193, U+2212, U+2215, U+FEFF, U+FFFD;
}
@font-face {
	font-family: "Lato";
	font-style: normal;
	font-weight: 900;
	font-display: swap;
	src: url(https://fonts.gstatic.com/s/lato/v24/S6u9w4BMUTPHh50XSwaPGR_p.woff2)
		format("woff2");
	unicode-range: U+0100-02AF, U+0304, U+0308, U+0329, U+1E00-1E9F, U+1EF2-1EFF,
		U+2020, U+20A0-20AB, U+20AD-20C0, U+2113, U+2C60-2C7F, U+A720-A7FF;
}
@font-face {
	font-family: "Lato";
	font-style: normal;
	font-weight: 900;
	font-display: swap;
	src: url(https://fonts.gstatic.com/s/lato/v24/S6u9w4BMUTPHh50XSwiPGQ.woff2)
		format("woff2");
	unicode-range: U+0000-00FF, U+0131, U+0152-0153, U+02BB-02BC, U+02C6, U+02DA,
		U+02DC, U+0304, U+0308, U+0329, U+2000-206F, U+2074, U+20AC, U+2122,
		U+2191, U+2193, U+2212, U+2215, U+FEFF, U+FFFD;
}
@font-face {
	font-family: "Lato";
	font-style: italic;
	font-weight: 400;
	font-display: swap;
	src: url(https://fonts.gstatic.com/s/lato/v24/S6u8w4BMUTPHjxsAUi-qJCY.woff2)
		format("woff2");
	unicode-range: U+0100-02AF, U+0304, U+0308, U+0329, U+1E00-1E9F, U+1EF2-1EFF,
		U+2020, U+20A0-20AB, U+20AD-20C0, U+2113, U+2C60-2C7F, U+A720-A7FF;
}
@font-face {
	font-family: "Lato";
	font-style: italic;
	font-weight: 400;
	font-display: swap;
	src: url(https://fonts.gstatic.com/s/lato/v24/S6u8w4BMUTPHjxsAXC-q.woff2)
		format("woff2");
	unicode-range: U+0000-00FF, U+0131, U+0152-0153, U+02BB-02BC, U+02C6, U+02DA,
		U+02DC, U+0304, U+0308, U+0329, U+2000-206F, U+2074, U+20AC, U+2122,
		U+2191, U+2193, U+2212, U+2215, U+FEFF, U+FFFD;
}
@font-face {
	font-family: "Lato";
	font-style: italic;
	font-weight: 700;
	font-display: swap;
	src: url(https://fonts.gstatic.com/s/lato/v24/S6u_w4BMUTPHjxsI5wq_FQft1dw.woff2)
		format("woff2");
	unicode-range: U+0100-02AF, U+0304, U+0308, U+0329, U+1E00-1E9F, U+1EF2-1EFF,
		U+2020, U+20A0-20AB, U+20AD-20C0, U+2113, U+2C60-2C7F, U+A720-A7FF;
}
@font-face {
	font-family: "Lato";
	font-style: italic;
	font-weight: 700;
	font-display: swap;
	src: url(https://fonts.gstatic.com/s/lato/v24/S6u_w4BMUTPHjxsI5wq_Gwft.woff2)
		format("woff2");
	unicode-range: U+0000-00FF, U+0131, U+0152-0153, U+02BB-02BC, U+02C6, U+02DA,
		U+02DC, U+0304, U+0308, U+0329, U+2000-206F, U+2074, U+20AC, U+2122,
		U+2191, U+2193, U+2212, U+2215, U+FEFF, U+FFFD;
}
@font-face {
	font-family: "Lato";
	font-style: normal;
	font-weight: 400;
	font-display: swap;
	src: url(https://fonts.gstatic.com/s/lato/v24/S6uyw4BMUTPHjxAwXjeu.woff2)
		format("woff2");
	unicode-range: U+0100-02AF, U+0304, U+0308, U+0329, U+1E00-1E9F, U+1EF2-1EFF,
		U+2020, U+20A0-20AB, U+20AD-20C0, U+2113, U+2C60-2C7F, U+A720-A7FF;
}
@font-face {
	font-family: "Lato";
	font-style: normal;
	font-weight: 400;
	font-display: swap;
	src: url(https://fonts.gstatic.com/s/lato/v24/S6uyw4BMUTPHjx4wXg.woff2)
		format("woff2");
	unicode-range: U+0000-00FF, U+0131, U+0152-0153, U+02BB-02BC, U+02C6, U+02DA,
		U+02DC, U+0304, U+0308, U+0329, U+2000-206F, U+2074, U+20AC, U+2122,
		U+2191, U+2193, U+2212, U+2215, U+FEFF, U+FFFD;
}
@font-face {
	font-family: "Lato";
	font-style: normal;
	font-weight: 700;
	font-display: swap;
	src: url(https://fonts.gstatic.com/s/lato/v24/S6u9w4BMUTPHh6UVSwaPGR_p.woff2)
		format("woff2");
	unicode-range: U+0100-02AF, U+0304, U+0308, U+0329, U+1E00-1E9F, U+1EF2-1EFF,
		U+2020, U+20A0-20AB, U+20AD-20C0, U+2113, U+2C60-2C7F, U+A720-A7FF;
}
@font-face {
	font-family: "Lato";
	font-style: normal;
	font-weight: 700;
	font-display: swap;
	src: url(https://fonts.gstatic.com/s/lato/v24/S6u9w4BMUTPHh6UVSwiPGQ.woff2)
		format("woff2");
	unicode-range: U+0000-00FF, U+0131, U+0152-0153, U+02BB-02BC, U+02C6, U+02DA,
		U+02DC, U+0304, U+0308, U+0329, U+2000-206F, U+2074, U+20AC, U+2122,
		U+2191, U+2193, U+2212, U+2215, U+FEFF, U+FFFD;
}
@font-face {
	font-family: "Lato";
	font-style: normal;
	font-weight: 900;
	font-display: swap;
	src: url(https://fonts.gstatic.com/s/lato/v24/S6u9w4BMUTPHh50XSwaPGR_p.woff2)
		format("woff2");
	unicode-range: U+0100-02AF, U+0304, U+0308, U+0329, U+1E00-1E9F, U+1EF2-1EFF,
		U+2020, U+20A0-20AB, U+20AD-20C0, U+2113, U+2C60-2C7F, U+A720-A7FF;
}
@font-face {
	font-family: "Lato";
	font-style: normal;
	font-weight: 900;
	font-display: swap;
	src: url(https://fonts.gstatic.com/s/lato/v24/S6u9w4BMUTPHh50XSwiPGQ.woff2)
		format("woff2");
	unicode-range: U+0000-00FF, U+0131, U+0152-0153, U+02BB-02BC, U+02C6, U+02DA,
		U+02DC, U+0304, U+0308, U+0329, U+2000-206F, U+2074, U+20AC, U+2122,
		U+2191, U+2193, U+2212, U+2215, U+FEFF, U+FFFD;
}
@font-face {
	font-family: "Lato";
	font-style: italic;
	font-weight: 400;
	font-display: swap;
	src: url(https://fonts.gstatic.com/s/lato/v24/S6u8w4BMUTPHjxsAUi-qJCY.woff2)
		format("woff2");
	unicode-range: U+0100-02AF, U+0304, U+0308, U+0329, U+1E00-1E9F, U+1EF2-1EFF,
		U+2020, U+20A0-20AB, U+20AD-20C0, U+2113, U+2C60-2C7F, U+A720-A7FF;
}
@font-face {
	font-family: "Lato";
	font-style: italic;
	font-weight: 400;
	font-display: swap;
	src: url(https://fonts.gstatic.com/s/lato/v24/S6u8w4BMUTPHjxsAXC-q.woff2)
		format("woff2");
	unicode-range: U+0000-00FF, U+0131, U+0152-0153, U+02BB-02BC, U+02C6, U+02DA,
		U+02DC, U+0304, U+0308, U+0329, U+2000-206F, U+2074, U+20AC, U+2122,
		U+2191, U+2193, U+2212, U+2215, U+FEFF, U+FFFD;
}
@font-face {
	font-family: "Lato";
	font-style: italic;
	font-weight: 700;
	font-display: swap;
	src: url(https://fonts.gstatic.com/s/lato/v24/S6u_w4BMUTPHjxsI5wq_FQft1dw.woff2)
		format("woff2");
	unicode-range: U+0100-02AF, U+0304, U+0308, U+0329, U+1E00-1E9F, U+1EF2-1EFF,
		U+2020, U+20A0-20AB, U+20AD-20C0, U+2113, U+2C60-2C7F, U+A720-A7FF;
}
@font-face {
	font-family: "Lato";
	font-style: italic;
	font-weight: 700;
	font-display: swap;
	src: url(https://fonts.gstatic.com/s/lato/v24/S6u_w4BMUTPHjxsI5wq_Gwft.woff2)
		format("woff2");
	unicode-range: U+0000-00FF, U+0131, U+0152-0153, U+02BB-02BC, U+02C6, U+02DA,
		U+02DC, U+0304, U+0308, U+0329, U+2000-206F, U+2074, U+20AC, U+2122,
		U+2191, U+2193, U+2212, U+2215, U+FEFF, U+FFFD;
}
@font-face {
	font-family: "Lato";
	font-style: normal;
	font-weight: 400;
	font-display: swap;
	src: url(https://fonts.gstatic.com/s/lato/v24/S6uyw4BMUTPHjxAwXjeu.woff2)
		format("woff2");
	unicode-range: U+0100-02AF, U+0304, U+0308, U+0329, U+1E00-1E9F, U+1EF2-1EFF,
		U+2020, U+20A0-20AB, U+20AD-20C0, U+2113, U+2C60-2C7F, U+A720-A7FF;
}
@font-face {
	font-family: "Lato";
	font-style: normal;
	font-weight: 400;
	font-display: swap;
	src: url(https://fonts.gstatic.com/s/lato/v24/S6uyw4BMUTPHjx4wXg.woff2)
		format("woff2");
	unicode-range: U+0000-00FF, U+0131, U+0152-0153, U+02BB-02BC, U+02C6, U+02DA,
		U+02DC, U+0304, U+0308, U+0329, U+2000-206F, U+2074, U+20AC, U+2122,
		U+2191, U+2193, U+2212, U+2215, U+FEFF, U+FFFD;
}
@font-face {
	font-family: "Lato";
	font-style: normal;
	font-weight: 700;
	font-display: swap;
	src: url(https://fonts.gstatic.com/s/lato/v24/S6u9w4BMUTPHh6UVSwaPGR_p.woff2)
		format("woff2");
	unicode-range: U+0100-02AF, U+0304, U+0308, U+0329, U+1E00-1E9F, U+1EF2-1EFF,
		U+2020, U+20A0-20AB, U+20AD-20C0, U+2113, U+2C60-2C7F, U+A720-A7FF;
}
@font-face {
	font-family: "Lato";
	font-style: normal;
	font-weight: 700;
	font-display: swap;
	src: url(https://fonts.gstatic.com/s/lato/v24/S6u9w4BMUTPHh6UVSwiPGQ.woff2)
		format("woff2");
	unicode-range: U+0000-00FF, U+0131, U+0152-0153, U+02BB-02BC, U+02C6, U+02DA,
		U+02DC, U+0304, U+0308, U+0329, U+2000-206F, U+2074, U+20AC, U+2122,
		U+2191, U+2193, U+2212, U+2215, U+FEFF, U+FFFD;
}
@font-face {
	font-family: "Lato";
	font-style: normal;
	font-weight: 900;
	font-display: swap;
	src: url(https://fonts.gstatic.com/s/lato/v24/S6u9w4BMUTPHh50XSwaPGR_p.woff2)
		format("woff2");
	unicode-range: U+0100-02AF, U+0304, U+0308, U+0329, U+1E00-1E9F, U+1EF2-1EFF,
		U+2020, U+20A0-20AB, U+20AD-20C0, U+2113, U+2C60-2C7F, U+A720-A7FF;
}
@font-face {
	font-family: "Lato";
	font-style: normal;
	font-weight: 900;
	font-display: swap;
	src: url(https://fonts.gstatic.com/s/lato/v24/S6u9w4BMUTPHh50XSwiPGQ.woff2)
		format("woff2");
	unicode-range: U+0000-00FF, U+0131, U+0152-0153, U+02BB-02BC, U+02C6, U+02DA,
		U+02DC, U+0304, U+0308, U+0329, U+2000-206F, U+2074, U+20AC, U+2122,
		U+2191, U+2193, U+2212, U+2215, U+FEFF, U+FFFD;
}
@font-face {
	font-family: "Lato";
	font-style: italic;
	font-weight: 400;
	font-display: swap;
	src: url(https://fonts.gstatic.com/s/lato/v24/S6u8w4BMUTPHjxsAUi-qJCY.woff2)
		format("woff2");
	unicode-range: U+0100-02AF, U+0304, U+0308, U+0329, U+1E00-1E9F, U+1EF2-1EFF,
		U+2020, U+20A0-20AB, U+20AD-20C0, U+2113, U+2C60-2C7F, U+A720-A7FF;
}
@font-face {
	font-family: "Lato";
	font-style: italic;
	font-weight: 400;
	font-display: swap;
	src: url(https://fonts.gstatic.com/s/lato/v24/S6u8w4BMUTPHjxsAXC-q.woff2)
		format("woff2");
	unicode-range: U+0000-00FF, U+0131, U+0152-0153, U+02BB-02BC, U+02C6, U+02DA,
		U+02DC, U+0304, U+0308, U+0329, U+2000-206F, U+2074, U+20AC, U+2122,
		U+2191, U+2193, U+2212, U+2215, U+FEFF, U+FFFD;
}
@font-face {
	font-family: "Lato";
	font-style: italic;
	font-weight: 700;
	font-display: swap;
	src: url(https://fonts.gstatic.com/s/lato/v24/S6u_w4BMUTPHjxsI5wq_FQft1dw.woff2)
		format("woff2");
	unicode-range: U+0100-02AF, U+0304, U+0308, U+0329, U+1E00-1E9F, U+1EF2-1EFF,
		U+2020, U+20A0-20AB, U+20AD-20C0, U+2113, U+2C60-2C7F, U+A720-A7FF;
}
@font-face {
	font-family: "Lato";
	font-style: italic;
	font-weight: 700;
	font-display: swap;
	src: url(https://fonts.gstatic.com/s/lato/v24/S6u_w4BMUTPHjxsI5wq_Gwft.woff2)
		format("woff2");
	unicode-range: U+0000-00FF, U+0131, U+0152-0153, U+02BB-02BC, U+02C6, U+02DA,
		U+02DC, U+0304, U+0308, U+0329, U+2000-206F, U+2074, U+20AC, U+2122,
		U+2191, U+2193, U+2212, U+2215, U+FEFF, U+FFFD;
}
@font-face {
	font-family: "Lato";
	font-style: normal;
	font-weight: 400;
	font-display: swap;
	src: url(https://fonts.gstatic.com/s/lato/v24/S6uyw4BMUTPHjxAwXjeu.woff2)
		format("woff2");
	unicode-range: U+0100-02AF, U+0304, U+0308, U+0329, U+1E00-1E9F, U+1EF2-1EFF,
		U+2020, U+20A0-20AB, U+20AD-20C0, U+2113, U+2C60-2C7F, U+A720-A7FF;
}
@font-face {
	font-family: "Lato";
	font-style: normal;
	font-weight: 400;
	font-display: swap;
	src: url(https://fonts.gstatic.com/s/lato/v24/S6uyw4BMUTPHjx4wXg.woff2)
		format("woff2");
	unicode-range: U+0000-00FF, U+0131, U+0152-0153, U+02BB-02BC, U+02C6, U+02DA,
		U+02DC, U+0304, U+0308, U+0329, U+2000-206F, U+2074, U+20AC, U+2122,
		U+2191, U+2193, U+2212, U+2215, U+FEFF, U+FFFD;
}
@font-face {
	font-family: "Lato";
	font-style: normal;
	font-weight: 700;
	font-display: swap;
	src: url(https://fonts.gstatic.com/s/lato/v24/S6u9w4BMUTPHh6UVSwaPGR_p.woff2)
		format("woff2");
	unicode-range: U+0100-02AF, U+0304, U+0308, U+0329, U+1E00-1E9F, U+1EF2-1EFF,
		U+2020, U+20A0-20AB, U+20AD-20C0, U+2113, U+2C60-2C7F, U+A720-A7FF;
}
@font-face {
	font-family: "Lato";
	font-style: normal;
	font-weight: 700;
	font-display: swap;
	src: url(https://fonts.gstatic.com/s/lato/v24/S6u9w4BMUTPHh6UVSwiPGQ.woff2)
		format("woff2");
	unicode-range: U+0000-00FF, U+0131, U+0152-0153, U+02BB-02BC, U+02C6, U+02DA,
		U+02DC, U+0304, U+0308, U+0329, U+2000-206F, U+2074, U+20AC, U+2122,
		U+2191, U+2193, U+2212, U+2215, U+FEFF, U+FFFD;
}
@font-face {
	font-family: "Lato";
	font-style: normal;
	font-weight: 900;
	font-display: swap;
	src: url(https://fonts.gstatic.com/s/lato/v24/S6u9w4BMUTPHh50XSwaPGR_p.woff2)
		format("woff2");
	unicode-range: U+0100-02AF, U+0304, U+0308, U+0329, U+1E00-1E9F, U+1EF2-1EFF,
		U+2020, U+20A0-20AB, U+20AD-20C0, U+2113, U+2C60-2C7F, U+A720-A7FF;
}
@font-face {
	font-family: "Lato";
	font-style: normal;
	font-weight: 900;
	font-display: swap;
	src: url(https://fonts.gstatic.com/s/lato/v24/S6u9w4BMUTPHh50XSwiPGQ.woff2)
		format("woff2");
	unicode-range: U+0000-00FF, U+0131, U+0152-0153, U+02BB-02BC, U+02C6, U+02DA,
		U+02DC, U+0304, U+0308, U+0329, U+2000-206F, U+2074, U+20AC, U+2122,
		U+2191, U+2193, U+2212, U+2215, U+FEFF, U+FFFD;
}
@font-face {
	font-family: "Lato";
	font-style: italic;
	font-weight: 400;
	font-display: swap;
	src: url(https://fonts.gstatic.com/s/lato/v24/S6u8w4BMUTPHjxsAUi-qJCY.woff2)
		format("woff2");
	unicode-range: U+0100-02AF, U+0304, U+0308, U+0329, U+1E00-1E9F, U+1EF2-1EFF,
		U+2020, U+20A0-20AB, U+20AD-20C0, U+2113, U+2C60-2C7F, U+A720-A7FF;
}
@font-face {
	font-family: "Lato";
	font-style: italic;
	font-weight: 400;
	font-display: swap;
	src: url(https://fonts.gstatic.com/s/lato/v24/S6u8w4BMUTPHjxsAXC-q.woff2)
		format("woff2");
	unicode-range: U+0000-00FF, U+0131, U+0152-0153, U+02BB-02BC, U+02C6, U+02DA,
		U+02DC, U+0304, U+0308, U+0329, U+2000-206F, U+2074, U+20AC, U+2122,
		U+2191, U+2193, U+2212, U+2215, U+FEFF, U+FFFD;
}
@font-face {
	font-family: "Lato";
	font-style: italic;
	font-weight: 700;
	font-display: swap;
	src: url(https://fonts.gstatic.com/s/lato/v24/S6u_w4BMUTPHjxsI5wq_FQft1dw.woff2)
		format("woff2");
	unicode-range: U+0100-02AF, U+0304, U+0308, U+0329, U+1E00-1E9F, U+1EF2-1EFF,
		U+2020, U+20A0-20AB, U+20AD-20C0, U+2113, U+2C60-2C7F, U+A720-A7FF;
}
@font-face {
	font-family: "Lato";
	font-style: italic;
	font-weight: 700;
	font-display: swap;
	src: url(https://fonts.gstatic.com/s/lato/v24/S6u_w4BMUTPHjxsI5wq_Gwft.woff2)
		format("woff2");
	unicode-range: U+0000-00FF, U+0131, U+0152-0153, U+02BB-02BC, U+02C6, U+02DA,
		U+02DC, U+0304, U+0308, U+0329, U+2000-206F, U+2074, U+20AC, U+2122,
		U+2191, U+2193, U+2212, U+2215, U+FEFF, U+FFFD;
}
@font-face {
	font-family: "Lato";
	font-style: normal;
	font-weight: 400;
	font-display: swap;
	src: url(https://fonts.gstatic.com/s/lato/v24/S6uyw4BMUTPHjxAwXjeu.woff2)
		format("woff2");
	unicode-range: U+0100-02AF, U+0304, U+0308, U+0329, U+1E00-1E9F, U+1EF2-1EFF,
		U+2020, U+20A0-20AB, U+20AD-20C0, U+2113, U+2C60-2C7F, U+A720-A7FF;
}
@font-face {
	font-family: "Lato";
	font-style: normal;
	font-weight: 400;
	font-display: swap;
	src: url(https://fonts.gstatic.com/s/lato/v24/S6uyw4BMUTPHjx4wXg.woff2)
		format("woff2");
	unicode-range: U+0000-00FF, U+0131, U+0152-0153, U+02BB-02BC, U+02C6, U+02DA,
		U+02DC, U+0304, U+0308, U+0329, U+2000-206F, U+2074, U+20AC, U+2122,
		U+2191, U+2193, U+2212, U+2215, U+FEFF, U+FFFD;
}
@font-face {
	font-family: "Lato";
	font-style: normal;
	font-weight: 700;
	font-display: swap;
	src: url(https://fonts.gstatic.com/s/lato/v24/S6u9w4BMUTPHh6UVSwaPGR_p.woff2)
		format("woff2");
	unicode-range: U+0100-02AF, U+0304, U+0308, U+0329, U+1E00-1E9F, U+1EF2-1EFF,
		U+2020, U+20A0-20AB, U+20AD-20C0, U+2113, U+2C60-2C7F, U+A720-A7FF;
}
@font-face {
	font-family: "Lato";
	font-style: normal;
	font-weight: 700;
	font-display: swap;
	src: url(https://fonts.gstatic.com/s/lato/v24/S6u9w4BMUTPHh6UVSwiPGQ.woff2)
		format("woff2");
	unicode-range: U+0000-00FF, U+0131, U+0152-0153, U+02BB-02BC, U+02C6, U+02DA,
		U+02DC, U+0304, U+0308, U+0329, U+2000-206F, U+2074, U+20AC, U+2122,
		U+2191, U+2193, U+2212, U+2215, U+FEFF, U+FFFD;
}
@font-face {
	font-family: "Lato";
	font-style: normal;
	font-weight: 900;
	font-display: swap;
	src: url(https://fonts.gstatic.com/s/lato/v24/S6u9w4BMUTPHh50XSwaPGR_p.woff2)
		format("woff2");
	unicode-range: U+0100-02AF, U+0304, U+0308, U+0329, U+1E00-1E9F, U+1EF2-1EFF,
		U+2020, U+20A0-20AB, U+20AD-20C0, U+2113, U+2C60-2C7F, U+A720-A7FF;
}
@font-face {
	font-family: "Lato";
	font-style: normal;
	font-weight: 900;
	font-display: swap;
	src: url(https://fonts.gstatic.com/s/lato/v24/S6u9w4BMUTPHh50XSwiPGQ.woff2)
		format("woff2");
	unicode-range: U+0000-00FF, U+0131, U+0152-0153, U+02BB-02BC, U+02C6, U+02DA,
		U+02DC, U+0304, U+0308, U+0329, U+2000-206F, U+2074, U+20AC, U+2122,
		U+2191, U+2193, U+2212, U+2215, U+FEFF, U+FFFD;
}
@font-face {
	font-family: "Lato";
	font-style: italic;
	font-weight: 400;
	font-display: swap;
	src: url(https://fonts.gstatic.com/s/lato/v24/S6u8w4BMUTPHjxsAUi-qJCY.woff2)
		format("woff2");
	unicode-range: U+0100-02AF, U+0304, U+0308, U+0329, U+1E00-1E9F, U+1EF2-1EFF,
		U+2020, U+20A0-20AB, U+20AD-20C0, U+2113, U+2C60-2C7F, U+A720-A7FF;
}
@font-face {
	font-family: "Lato";
	font-style: italic;
	font-weight: 400;
	font-display: swap;
	src: url(https://fonts.gstatic.com/s/lato/v24/S6u8w4BMUTPHjxsAXC-q.woff2)
		format("woff2");
	unicode-range: U+0000-00FF, U+0131, U+0152-0153, U+02BB-02BC, U+02C6, U+02DA,
		U+02DC, U+0304, U+0308, U+0329, U+2000-206F, U+2074, U+20AC, U+2122,
		U+2191, U+2193, U+2212, U+2215, U+FEFF, U+FFFD;
}
@font-face {
	font-family: "Lato";
	font-style: italic;
	font-weight: 700;
	font-display: swap;
	src: url(https://fonts.gstatic.com/s/lato/v24/S6u_w4BMUTPHjxsI5wq_FQft1dw.woff2)
		format("woff2");
	unicode-range: U+0100-02AF, U+0304, U+0308, U+0329, U+1E00-1E9F, U+1EF2-1EFF,
		U+2020, U+20A0-20AB, U+20AD-20C0, U+2113, U+2C60-2C7F, U+A720-A7FF;
}
@font-face {
	font-family: "Lato";
	font-style: italic;
	font-weight: 700;
	font-display: swap;
	src: url(https://fonts.gstatic.com/s/lato/v24/S6u_w4BMUTPHjxsI5wq_Gwft.woff2)
		format("woff2");
	unicode-range: U+0000-00FF, U+0131, U+0152-0153, U+02BB-02BC, U+02C6, U+02DA,
		U+02DC, U+0304, U+0308, U+0329, U+2000-206F, U+2074, U+20AC, U+2122,
		U+2191, U+2193, U+2212, U+2215, U+FEFF, U+FFFD;
}
@font-face {
	font-family: "Lato";
	font-style: normal;
	font-weight: 400;
	font-display: swap;
	src: url(https://fonts.gstatic.com/s/lato/v24/S6uyw4BMUTPHjxAwXjeu.woff2)
		format("woff2");
	unicode-range: U+0100-02AF, U+0304, U+0308, U+0329, U+1E00-1E9F, U+1EF2-1EFF,
		U+2020, U+20A0-20AB, U+20AD-20C0, U+2113, U+2C60-2C7F, U+A720-A7FF;
}
@font-face {
	font-family: "Lato";
	font-style: normal;
	font-weight: 400;
	font-display: swap;
	src: url(https://fonts.gstatic.com/s/lato/v24/S6uyw4BMUTPHjx4wXg.woff2)
		format("woff2");
	unicode-range: U+0000-00FF, U+0131, U+0152-0153, U+02BB-02BC, U+02C6, U+02DA,
		U+02DC, U+0304, U+0308, U+0329, U+2000-206F, U+2074, U+20AC, U+2122,
		U+2191, U+2193, U+2212, U+2215, U+FEFF, U+FFFD;
}
@font-face {
	font-family: "Lato";
	font-style: normal;
	font-weight: 700;
	font-display: swap;
	src: url(https://fonts.gstatic.com/s/lato/v24/S6u9w4BMUTPHh6UVSwaPGR_p.woff2)
		format("woff2");
	unicode-range: U+0100-02AF, U+0304, U+0308, U+0329, U+1E00-1E9F, U+1EF2-1EFF,
		U+2020, U+20A0-20AB, U+20AD-20C0, U+2113, U+2C60-2C7F, U+A720-A7FF;
}
@font-face {
	font-family: "Lato";
	font-style: normal;
	font-weight: 700;
	font-display: swap;
	src: url(https://fonts.gstatic.com/s/lato/v24/S6u9w4BMUTPHh6UVSwiPGQ.woff2)
		format("woff2");
	unicode-range: U+0000-00FF, U+0131, U+0152-0153, U+02BB-02BC, U+02C6, U+02DA,
		U+02DC, U+0304, U+0308, U+0329, U+2000-206F, U+2074, U+20AC, U+2122,
		U+2191, U+2193, U+2212, U+2215, U+FEFF, U+FFFD;
}
@font-face {
	font-family: "Lato";
	font-style: normal;
	font-weight: 900;
	font-display: swap;
	src: url(https://fonts.gstatic.com/s/lato/v24/S6u9w4BMUTPHh50XSwaPGR_p.woff2)
		format("woff2");
	unicode-range: U+0100-02AF, U+0304, U+0308, U+0329, U+1E00-1E9F, U+1EF2-1EFF,
		U+2020, U+20A0-20AB, U+20AD-20C0, U+2113, U+2C60-2C7F, U+A720-A7FF;
}
@font-face {
	font-family: "Lato";
	font-style: normal;
	font-weight: 900;
	font-display: swap;
	src: url(https://fonts.gstatic.com/s/lato/v24/S6u9w4BMUTPHh50XSwiPGQ.woff2)
		format("woff2");
	unicode-range: U+0000-00FF, U+0131, U+0152-0153, U+02BB-02BC, U+02C6, U+02DA,
		U+02DC, U+0304, U+0308, U+0329, U+2000-206F, U+2074, U+20AC, U+2122,
		U+2191, U+2193, U+2212, U+2215, U+FEFF, U+FFFD;
}
@font-face {
	font-family: "Font Awesome 5 Free";
	font-style: normal;
	font-weight: 400;
	font-display: auto;
	src: url(https://use.fontawesome.com/releases/v5.8.1/webfonts/fa-regular-400.eot);
	src: url(https://use.fontawesome.com/releases/v5.8.1/webfonts/fa-regular-400.eot?#iefix)
			format("embedded-opentype"),
		url(https://use.fontawesome.com/releases/v5.8.1/webfonts/fa-regular-400.woff2)
			format("woff2"),
		url(https://use.fontawesome.com/releases/v5.8.1/webfonts/fa-regular-400.woff)
			format("woff"),
		url(https://use.fontawesome.com/releases/v5.8.1/webfonts/fa-regular-400.ttf)
			format("truetype"),
		url(https://use.fontawesome.com/releases/v5.8.1/webfonts/fa-regular-400.svg#fontawesome)
			format("svg");
}
@font-face {
	font-family: "Font Awesome 5 Free";
	font-style: normal;
	font-weight: 900;
	font-display: auto;
	src: url(https://use.fontawesome.com/releases/v5.8.1/webfonts/fa-solid-900.eot);
	src: url(https://use.fontawesome.com/releases/v5.8.1/webfonts/fa-solid-900.eot?#iefix)
			format("embedded-opentype"),
		url(https://use.fontawesome.com/releases/v5.8.1/webfonts/fa-solid-900.woff2)
			format("woff2"),
		url(https://use.fontawesome.com/releases/v5.8.1/webfonts/fa-solid-900.woff)
			format("woff"),
		url(https://use.fontawesome.com/releases/v5.8.1/webfonts/fa-solid-900.ttf)
			format("truetype"),
		url(https://use.fontawesome.com/releases/v5.8.1/webfonts/fa-solid-900.svg#fontawesome)
			format("svg");
}
@font-face {
	font-family: "Font Awesome 5 Free";
	font-style: normal;
	font-weight: 400;
	font-display: auto;
	src: url(https://use.fontawesome.com/releases/v5.8.1/webfonts/fa-regular-400.eot);
	src: url(https://use.fontawesome.com/releases/v5.8.1/webfonts/fa-regular-400.eot?#iefix)
			format("embedded-opentype"),
		url(https://use.fontawesome.com/releases/v5.8.1/webfonts/fa-regular-400.woff2)
			format("woff2"),
		url(https://use.fontawesome.com/releases/v5.8.1/webfonts/fa-regular-400.woff)
			format("woff"),
		url(https://use.fontawesome.com/releases/v5.8.1/webfonts/fa-regular-400.ttf)
			format("truetype"),
		url(https://use.fontawesome.com/releases/v5.8.1/webfonts/fa-regular-400.svg#fontawesome)
			format("svg");
}
@font-face {
	font-family: "Font Awesome 5 Free";
	font-style: normal;
	font-weight: 900;
	font-display: auto;
	src: url(https://use.fontawesome.com/releases/v5.8.1/webfonts/fa-solid-900.eot);
	src: url(https://use.fontawesome.com/releases/v5.8.1/webfonts/fa-solid-900.eot?#iefix)
			format("embedded-opentype"),
		url(https://use.fontawesome.com/releases/v5.8.1/webfonts/fa-solid-900.woff2)
			format("woff2"),
		url(https://use.fontawesome.com/releases/v5.8.1/webfonts/fa-solid-900.woff)
			format("woff"),
		url(https://use.fontawesome.com/releases/v5.8.1/webfonts/fa-solid-900.ttf)
			format("truetype"),
		url(https://use.fontawesome.com/releases/v5.8.1/webfonts/fa-solid-900.svg#fontawesome)
			format("svg");
}
@font-face {
	font-family: "Font Awesome 5 Free";
	font-style: normal;
	font-weight: 400;
	font-display: auto;
	src: url(https://use.fontawesome.com/releases/v5.8.1/webfonts/fa-regular-400.eot);
	src: url(https://use.fontawesome.com/releases/v5.8.1/webfonts/fa-regular-400.eot?#iefix)
			format("embedded-opentype"),
		url(https://use.fontawesome.com/releases/v5.8.1/webfonts/fa-regular-400.woff2)
			format("woff2"),
		url(https://use.fontawesome.com/releases/v5.8.1/webfonts/fa-regular-400.woff)
			format("woff"),
		url(https://use.fontawesome.com/releases/v5.8.1/webfonts/fa-regular-400.ttf)
			format("truetype"),
		url(https://use.fontawesome.com/releases/v5.8.1/webfonts/fa-regular-400.svg#fontawesome)
			format("svg");
}
@font-face {
	font-family: "Font Awesome 5 Free";
	font-style: normal;
	font-weight: 900;
	font-display: auto;
	src: url(https://use.fontawesome.com/releases/v5.8.1/webfonts/fa-solid-900.eot);
	src: url(https://use.fontawesome.com/releases/v5.8.1/webfonts/fa-solid-900.eot?#iefix)
			format("embedded-opentype"),
		url(https://use.fontawesome.com/releases/v5.8.1/webfonts/fa-solid-900.woff2)
			format("woff2"),
		url(https://use.fontawesome.com/releases/v5.8.1/webfonts/fa-solid-900.woff)
			format("woff"),
		url(https://use.fontawesome.com/releases/v5.8.1/webfonts/fa-solid-900.ttf)
			format("truetype"),
		url(https://use.fontawesome.com/releases/v5.8.1/webfonts/fa-solid-900.svg#fontawesome)
			format("svg");
}
@font-face {
	font-family: "Font Awesome 5 Free";
	font-style: normal;
	font-weight: 400;
	font-display: auto;
	src: url(https://use.fontawesome.com/releases/v5.8.1/webfonts/fa-regular-400.eot);
	src: url(https://use.fontawesome.com/releases/v5.8.1/webfonts/fa-regular-400.eot?#iefix)
			format("embedded-opentype"),
		url(https://use.fontawesome.com/releases/v5.8.1/webfonts/fa-regular-400.woff2)
			format("woff2"),
		url(https://use.fontawesome.com/releases/v5.8.1/webfonts/fa-regular-400.woff)
			format("woff"),
		url(https://use.fontawesome.com/releases/v5.8.1/webfonts/fa-regular-400.ttf)
			format("truetype"),
		url(https://use.fontawesome.com/releases/v5.8.1/webfonts/fa-regular-400.svg#fontawesome)
			format("svg");
}
@font-face {
	font-family: "Font Awesome 5 Free";
	font-style: normal;
	font-weight: 900;
	font-display: auto;
	src: url(https://use.fontawesome.com/releases/v5.8.1/webfonts/fa-solid-900.eot);
	src: url(https://use.fontawesome.com/releases/v5.8.1/webfonts/fa-solid-900.eot?#iefix)
			format("embedded-opentype"),
		url(https://use.fontawesome.com/releases/v5.8.1/webfonts/fa-solid-900.woff2)
			format("woff2"),
		url(https://use.fontawesome.com/releases/v5.8.1/webfonts/fa-solid-900.woff)
			format("woff"),
		url(https://use.fontawesome.com/releases/v5.8.1/webfonts/fa-solid-900.ttf)
			format("truetype"),
		url(https://use.fontawesome.com/releases/v5.8.1/webfonts/fa-solid-900.svg#fontawesome)
			format("svg");
}
@font-face {
	font-family: "Font Awesome 5 Free";
	font-style: normal;
	font-weight: 400;
	font-display: auto;
	src: url(https://use.fontawesome.com/releases/v5.8.1/webfonts/fa-regular-400.eot);
	src: url(https://use.fontawesome.com/releases/v5.8.1/webfonts/fa-regular-400.eot?#iefix)
			format("embedded-opentype"),
		url(https://use.fontawesome.com/releases/v5.8.1/webfonts/fa-regular-400.woff2)
			format("woff2"),
		url(https://use.fontawesome.com/releases/v5.8.1/webfonts/fa-regular-400.woff)
			format("woff"),
		url(https://use.fontawesome.com/releases/v5.8.1/webfonts/fa-regular-400.ttf)
			format("truetype"),
		url(https://use.fontawesome.com/releases/v5.8.1/webfonts/fa-regular-400.svg#fontawesome)
			format("svg");
}
@font-face {
	font-family: "Font Awesome 5 Free";
	font-style: normal;
	font-weight: 900;
	font-display: auto;
	src: url(https://use.fontawesome.com/releases/v5.8.1/webfonts/fa-solid-900.eot);
	src: url(https://use.fontawesome.com/releases/v5.8.1/webfonts/fa-solid-900.eot?#iefix)
			format("embedded-opentype"),
		url(https://use.fontawesome.com/releases/v5.8.1/webfonts/fa-solid-900.woff2)
			format("woff2"),
		url(https://use.fontawesome.com/releases/v5.8.1/webfonts/fa-solid-900.woff)
			format("woff"),
		url(https://use.fontawesome.com/releases/v5.8.1/webfonts/fa-solid-900.ttf)
			format("truetype"),
		url(https://use.fontawesome.com/releases/v5.8.1/webfonts/fa-solid-900.svg#fontawesome)
			format("svg");
}
@font-face {
	font-family: "Font Awesome 5 Free";
	font-style: normal;
	font-weight: 400;
	font-display: auto;
	src: url(https://use.fontawesome.com/releases/v5.8.1/webfonts/fa-regular-400.eot);
	src: url(https://use.fontawesome.com/releases/v5.8.1/webfonts/fa-regular-400.eot?#iefix)
			format("embedded-opentype"),
		url(https://use.fontawesome.com/releases/v5.8.1/webfonts/fa-regular-400.woff2)
			format("woff2"),
		url(https://use.fontawesome.com/releases/v5.8.1/webfonts/fa-regular-400.woff)
			format("woff"),
		url(https://use.fontawesome.com/releases/v5.8.1/webfonts/fa-regular-400.ttf)
			format("truetype"),
		url(https://use.fontawesome.com/releases/v5.8.1/webfonts/fa-regular-400.svg#fontawesome)
			format("svg");
}
@font-face {
	font-family: "Font Awesome 5 Free";
	font-style: normal;
	font-weight: 900;
	font-display: auto;
	src: url(https://use.fontawesome.com/releases/v5.8.1/webfonts/fa-solid-900.eot);
	src: url(https://use.fontawesome.com/releases/v5.8.1/webfonts/fa-solid-900.eot?#iefix)
			format("embedded-opentype"),
		url(https://use.fontawesome.com/releases/v5.8.1/webfonts/fa-solid-900.woff2)
			format("woff2"),
		url(https://use.fontawesome.com/releases/v5.8.1/webfonts/fa-solid-900.woff)
			format("woff"),
		url(https://use.fontawesome.com/releases/v5.8.1/webfonts/fa-solid-900.ttf)
			format("truetype"),
		url(https://use.fontawesome.com/releases/v5.8.1/webfonts/fa-solid-900.svg#fontawesome)
			format("svg");
}
@font-face {
	font-family: "Font Awesome 5 Free";
	font-style: normal;
	font-weight: 400;
	font-display: auto;
	src: url(https://use.fontawesome.com/releases/v5.8.1/webfonts/fa-regular-400.eot);
	src: url(https://use.fontawesome.com/releases/v5.8.1/webfonts/fa-regular-400.eot?#iefix)
			format("embedded-opentype"),
		url(https://use.fontawesome.com/releases/v5.8.1/webfonts/fa-regular-400.woff2)
			format("woff2"),
		url(https://use.fontawesome.com/releases/v5.8.1/webfonts/fa-regular-400.woff)
			format("woff"),
		url(https://use.fontawesome.com/releases/v5.8.1/webfonts/fa-regular-400.ttf)
			format("truetype"),
		url(https://use.fontawesome.com/releases/v5.8.1/webfonts/fa-regular-400.svg#fontawesome)
			format("svg");
}
@font-face {
	font-family: "Font Awesome 5 Free";
	font-style: normal;
	font-weight: 900;
	font-display: auto;
	src: url(https://use.fontawesome.com/releases/v5.8.1/webfonts/fa-solid-900.eot);
	src: url(https://use.fontawesome.com/releases/v5.8.1/webfonts/fa-solid-900.eot?#iefix)
			format("embedded-opentype"),
		url(https://use.fontawesome.com/releases/v5.8.1/webfonts/fa-solid-900.woff2)
			format("woff2"),
		url(https://use.fontawesome.com/releases/v5.8.1/webfonts/fa-solid-900.woff)
			format("woff"),
		url(https://use.fontawesome.com/releases/v5.8.1/webfonts/fa-solid-900.ttf)
			format("truetype"),
		url(https://use.fontawesome.com/releases/v5.8.1/webfonts/fa-solid-900.svg#fontawesome)
			format("svg");
}
@font-face {
	font-family: "Font Awesome 5 Free";
	font-style: normal;
	font-weight: 400;
	font-display: auto;
	src: url(https://use.fontawesome.com/releases/v5.8.1/webfonts/fa-regular-400.eot);
	src: url(https://use.fontawesome.com/releases/v5.8.1/webfonts/fa-regular-400.eot?#iefix)
			format("embedded-opentype"),
		url(https://use.fontawesome.com/releases/v5.8.1/webfonts/fa-regular-400.woff2)
			format("woff2"),
		url(https://use.fontawesome.com/releases/v5.8.1/webfonts/fa-regular-400.woff)
			format("woff"),
		url(https://use.fontawesome.com/releases/v5.8.1/webfonts/fa-regular-400.ttf)
			format("truetype"),
		url(https://use.fontawesome.com/releases/v5.8.1/webfonts/fa-regular-400.svg#fontawesome)
			format("svg");
}
@font-face {
	font-family: "Font Awesome 5 Free";
	font-style: normal;
	font-weight: 900;
	font-display: auto;
	src: url(https://use.fontawesome.com/releases/v5.8.1/webfonts/fa-solid-900.eot);
	src: url(https://use.fontawesome.com/releases/v5.8.1/webfonts/fa-solid-900.eot?#iefix)
			format("embedded-opentype"),
		url(https://use.fontawesome.com/releases/v5.8.1/webfonts/fa-solid-900.woff2)
			format("woff2"),
		url(https://use.fontawesome.com/releases/v5.8.1/webfonts/fa-solid-900.woff)
			format("woff"),
		url(https://use.fontawesome.com/releases/v5.8.1/webfonts/fa-solid-900.ttf)
			format("truetype"),
		url(https://use.fontawesome.com/releases/v5.8.1/webfonts/fa-solid-900.svg#fontawesome)
			format("svg");
}
