/*! CSS Used from: https://stackpath.bootstrapcdn.com/bootstrap/4.5.2/css/bootstrap.min.css */
*,
::after,
::before {
	box-sizing: border-box;
}
section {
	display: block;
}
h1 {
	margin-top: 0;
	margin-bottom: 0.5rem;
}
p {
	margin-top: 0;
	margin-bottom: 1rem;
}
h1 {
	margin-bottom: 0.5rem;
	font-weight: 500;
	line-height: 1.2;
}
h1 {
	font-size: 2.5rem;
}
.text-muted {
	color: #6c757d !important;
}
@media print {
	*,
	::after,
	::before {
		text-shadow: none !important;
		box-shadow: none !important;
	}
	p {
		orphans: 3;
		widows: 3;
	}
}
/*! CSS Used from: Embedded */
* {
	font-family: "Lato", sans-serif;
}
h1 {
	font-family: "Lato", sans-serif !important;
}
p {
	margin: 0;
	padding: 0;
}
h1 {
	font-weight: normal;
	margin: 0;
	padding: 0;
}
.activeTab {
	color: #d31148;
}
.create-edit-form {
	scroll-margin-top: 70px;
}
@media (max-width: 600px) {
	.create-edit-form {
		scroll-margin-top: -190px;
	}
}
.create-edit-form .info-box-container .db-header {
	display: block;
}
.create-edit-form .info-box-container .db-header div {
	font-size: 21px;
}
.create-edit-form .info-box-container .db-header p {
	font-size: 15px;
	margin-top: 15px;
}
.create-edit-form .form-box-layout {
	padding: 0px;
	margin-top: 0px;
}
.form-box-layout {
	position: relative;
	width: 100%;
	padding-right: 15px;
	padding-left: 15px;
}
@media (max-width: 991px) {
	.form-box-layout {
		padding-bottom: 20px;
	}
}
.preview-market-map {
	background-color: #fff !important;
	padding: 0 !important;
	border: 1px solid #eeeeee;
	border-radius: 5px;
}
.preview-market-map .network-map-navigation {
	margin: 0 !important;
}
.market-map-header {
	display: flex;
	justify-content: space-between;
}
.market-map-header.db-header div:last-child {
	flex-shrink: 0;
}
.market-map-header.db-header p {
	font-size: 16px !important;
}
@media (max-width: 699px) {
	.market-map-header {
		display: block;
	}
}
.map-row {
	margin: 0 !important;

	.col {
		padding: 0;
		padding-right: 20px;
	}

	.map-logo {
		display : flex;
		justify-content: center;
		align-items : center;
		border-left: 1px solid #eeeeee;
		padding: 0 20px;
	
		a {
			max-width: 200px;
		}
	}
}
p {
	font-size: 14px;
}
*:focus {
	outline: none !important;
}
/*! CSS Used from: Embedded */
*,
::after,
::before {
	box-sizing: border-box;
}
section {
	display: block;
}
h1 {
	margin-top: 0;
	margin-bottom: 0.5rem;
}
p {
	margin-top: 0;
	margin-bottom: 1rem;
}
h1 {
	margin-bottom: 0.5rem;
	font-weight: 500;
	line-height: 1.2;
}
h1 {
	font-size: 2.5rem;
}
.text-muted {
	color: #6c757d !important;
}
@media print {
	*,
	::after,
	::before {
		text-shadow: none !important;
		box-shadow: none !important;
	}
	p {
		orphans: 3;
		widows: 3;
	}
}
/*! CSS Used from: Embedded */
*,
::after,
::before {
	box-sizing: border-box;
}
@media print {
	*,
	::after,
	::before {
		text-shadow: none !important;
		box-shadow: none !important;
	}
}
* {
	font-family: "Lato", sans-serif;
}
*:focus {
	outline: none !important;
}
*,
::after,
::before {
	box-sizing: border-box;
}
@media print {
	*,
	::after,
	::before {
		text-shadow: none !important;
		box-shadow: none !important;
	}
}
* {
	font-family: "Lato", sans-serif;
	letter-spacing: 0.6px;
}
*:focus {
	outline: none;
}
*,
::after,
::before {
	box-sizing: border-box;
}
@media print {
	*,
	::after,
	::before {
		text-shadow: none !important;
		box-shadow: none !important;
	}
}
/*! CSS Used from: Embedded */
*,
::after,
::before {
	box-sizing: border-box;
}
p {
	margin-top: 0;
	margin-bottom: 1rem;
}
@media print {
	*,
	::after,
	::before {
		text-shadow: none !important;
		box-shadow: none !important;
	}
	p {
		orphans: 3;
		widows: 3;
	}
}
* {
	font-family: "Lato", sans-serif;
}
p {
	margin: 0;
	padding: 0;
}
p {
	font-size: 14px;
}
*:focus {
	outline: none !important;
}
*,
::after,
::before {
	box-sizing: border-box;
}
p {
	margin-top: 0;
	margin-bottom: 1rem;
}
@media print {
	*,
	::after,
	::before {
		text-shadow: none !important;
		box-shadow: none !important;
	}
	p {
		orphans: 3;
		widows: 3;
	}
}
* {
	font-family: "Lato", sans-serif;
	letter-spacing: 0.6px;
}
*:focus {
	outline: none;
}
p {
	margin-bottom: 0;
	font-size: 14px;
}
*,
::after,
::before {
	box-sizing: border-box;
}
p {
	margin-top: 0;
	margin-bottom: 1rem;
}
@media print {
	*,
	::after,
	::before {
		text-shadow: none !important;
		box-shadow: none !important;
	}
	p {
		orphans: 3;
		widows: 3;
	}
}
*,
::after,
::before {
	box-sizing: border-box;
}
@media print {
	*,
	::after,
	::before {
		text-shadow: none !important;
		box-shadow: none !important;
	}
}
* {
	font-family: "Lato", sans-serif;
}
*:focus {
	outline: none !important;
}
*,
::after,
::before {
	box-sizing: border-box;
}
@media print {
	*,
	::after,
	::before {
		text-shadow: none !important;
		box-shadow: none !important;
	}
}
* {
	font-family: "Lato", sans-serif;
	letter-spacing: 0.6px;
}
*:focus {
	outline: none;
}
*,
::after,
::before {
	box-sizing: border-box;
}
@media print {
	*,
	::after,
	::before {
		text-shadow: none !important;
		box-shadow: none !important;
	}
}
/*! CSS Used from: Embedded */
/*! @import https://use.fontawesome.com/releases/v5.8.1/css/all.css */
.fa {
	-moz-osx-font-smoothing: grayscale;
	-webkit-font-smoothing: antialiased;
	display: inline-block;
	font-style: normal;
	font-variant: normal;
	text-rendering: auto;
	line-height: 1;
}
.fa-chart-line:before {
	content: "\f201";
}
.fa-table:before {
	content: "\f0ce";
}
.fa-th-large:before {
	content: "\f009";
}
.fa {
	font-family: "Font Awesome 5 Free";
}
.fa {
	font-weight: 900;
}
/*! end @import */
/*! CSS Used from: Embedded */
@media (max-width: 575px) {
	.hidden-md-down {
		display: none !important;
	}
}
@media (min-width: 576px) and (max-width: 767px) {
	.hidden-md-down {
		display: none !important;
	}
}
@media (min-width: 768px) and (max-width: 991px) {
	.hidden-md-down {
		display: none !important;
	}
}
@media (max-width: 767px) {
	.map-row {
		display: block;

		.col {
			padding: 0;
		}

		.map-logo {
			padding: 20px 0;
			border: 0 !important
		}
	}
}
.network-map-main .network-map-navigation {
	margin: 10px 30px;
	margin-bottom: 25px;
}
.network-map-main .navigation-wrapper {
	border-bottom: 1px solid #eeeeee;
}
.network-map-main .navigation-wrapper div {
	display: table-cell;
	font-size: 18px !important;
	font-weight: 600;
	padding: 10px 14px;
	line-height: 40px;
	min-width: 110px;
	text-align: center;
}
.network-map-main .navigation-wrapper .activeTab {
	border-bottom: 3px solid #d31148 !important;
	color: #d31148 !important;
	cursor: pointer;
}
.network-map-main .navigation-wrapper .nonActiveTab {
	color: #343a40 !important;
	cursor: pointer;
}
.network-map-main .navigation-wrapper .activeTab i,
.network-map-main .navigation-wrapper .nonActiveTab i {
	margin-right: 8px;
}
@media (max-width: 600px) {
	.network-map-main .network-map-navigation {
		margin: 30px 0 !important;
	}
}
* {
	font-family: "Lato", sans-serif;
	letter-spacing: 0.6px;
}
*:focus {
	outline: none;
}
p {
	margin-bottom: 0;
	font-size: 14px;
}
.fa {
	font-family: "Font Awesome 5 Free" !important;
}
section {
	margin: 0;
	padding: 15px 0;
	background: #f8f8f8;
}
.content-box {
	width: 100%;
	margin: 0 auto;
	padding: 30px;
	position: relative;
	z-index: 10;
	margin-bottom: 30px;
}
@media (min-width: 1500px) {
	.content-box {
		max-width: 1600px;
	}
}
@media (max-width: 1000px) {
	.content-box {
		padding: 15px !important;
	}
}
.db-header {
	display: flex;
	justify-content: space-between;
	margin-bottom: 25px;
}
.db-header div {
	font-size: 19px;
	font-weight: 600;
}
.db-header div p {
	font-size: 15px;
	font-weight: 400;
}
.mid-width-info-box {
	box-shadow: 0 40px 40px -20px rgba(23, 29, 48, 0.2);
	font-family: "Lato", sans-serif !important;
	background: #fff;
}
.mid-width-info-box .info-box-container {
	padding: 30px;
}
.mid-width-info-box .info-box-container .info-box-body {
	letter-spacing: 0.06px;
	line-height: 26px;
	font-size: 16px;
}

.market-map-header {
	color: #5d656d;
}

.nonActiveTab {
	color: #343a40 !important;
	cursor: pointer;
}

.nonActiveTab i{
	color: #343a40 !important;
	cursor: pointer;
}
.network-map-main .navigation-wrapper .activeTab i,
.network-map-main .navigation-wrapper .nonActiveTab i {
	margin-right: 8px;
}

 .activeTab {
	border-bottom: 3px solid #d31148 !important;
	color: #d31148 !important;
	cursor: pointer;
  }

  .activeTab i{
	color: #d31148 !important;
  }

.mid-width-info-box
	.info-box-container
	.info-box-body
	*:not(.map-dashboard-graph *) {
	font-size: 16px;
}
.footer-margin-offset {
	margin-bottom: -50px !important;
}
/*! CSS Used from: Embedded */
*,
::after,
::before {
	box-sizing: border-box;
}
section {
	display: block;
}
h1 {
	margin-top: 0;
	margin-bottom: 0.5rem;
}
p {
	margin-top: 0;
	margin-bottom: 1rem;
}
h1 {
	margin-bottom: 0.5rem;
	font-weight: 500;
	line-height: 1.2;
}
h1 {
	font-size: 2.5rem;
}

.directory {
	background-color: #f3f4f4 !important;
}

.text-muted {
	color: #6c757d !important;
}
@media print {
	*,
	::after,
	::before {
		text-shadow: none !important;
		box-shadow: none !important;
	}
	p {
		orphans: 3;
		widows: 3;
	}
}
/*! CSS Used fontfaces */
@font-face {
	font-family: "Lato";
	font-style: italic;
	font-weight: 400;
	font-display: swap;
	src: url(https://fonts.gstatic.com/s/lato/v24/S6u8w4BMUTPHjxsAUi-qJCY.woff2)
		format("woff2");
	unicode-range: U+0100-02AF, U+0304, U+0308, U+0329, U+1E00-1E9F, U+1EF2-1EFF,
		U+2020, U+20A0-20AB, U+20AD-20C0, U+2113, U+2C60-2C7F, U+A720-A7FF;
}
@font-face {
	font-family: "Lato";
	font-style: italic;
	font-weight: 400;
	font-display: swap;
	src: url(https://fonts.gstatic.com/s/lato/v24/S6u8w4BMUTPHjxsAXC-q.woff2)
		format("woff2");
	unicode-range: U+0000-00FF, U+0131, U+0152-0153, U+02BB-02BC, U+02C6, U+02DA,
		U+02DC, U+0304, U+0308, U+0329, U+2000-206F, U+2074, U+20AC, U+2122,
		U+2191, U+2193, U+2212, U+2215, U+FEFF, U+FFFD;
}
@font-face {
	font-family: "Lato";
	font-style: italic;
	font-weight: 700;
	font-display: swap;
	src: url(https://fonts.gstatic.com/s/lato/v24/S6u_w4BMUTPHjxsI5wq_FQft1dw.woff2)
		format("woff2");
	unicode-range: U+0100-02AF, U+0304, U+0308, U+0329, U+1E00-1E9F, U+1EF2-1EFF,
		U+2020, U+20A0-20AB, U+20AD-20C0, U+2113, U+2C60-2C7F, U+A720-A7FF;
}
@font-face {
	font-family: "Lato";
	font-style: italic;
	font-weight: 700;
	font-display: swap;
	src: url(https://fonts.gstatic.com/s/lato/v24/S6u_w4BMUTPHjxsI5wq_Gwft.woff2)
		format("woff2");
	unicode-range: U+0000-00FF, U+0131, U+0152-0153, U+02BB-02BC, U+02C6, U+02DA,
		U+02DC, U+0304, U+0308, U+0329, U+2000-206F, U+2074, U+20AC, U+2122,
		U+2191, U+2193, U+2212, U+2215, U+FEFF, U+FFFD;
}
@font-face {
	font-family: "Lato";
	font-style: normal;
	font-weight: 400;
	font-display: swap;
	src: url(https://fonts.gstatic.com/s/lato/v24/S6uyw4BMUTPHjxAwXjeu.woff2)
		format("woff2");
	unicode-range: U+0100-02AF, U+0304, U+0308, U+0329, U+1E00-1E9F, U+1EF2-1EFF,
		U+2020, U+20A0-20AB, U+20AD-20C0, U+2113, U+2C60-2C7F, U+A720-A7FF;
}
@font-face {
	font-family: "Lato";
	font-style: normal;
	font-weight: 400;
	font-display: swap;
	src: url(https://fonts.gstatic.com/s/lato/v24/S6uyw4BMUTPHjx4wXg.woff2)
		format("woff2");
	unicode-range: U+0000-00FF, U+0131, U+0152-0153, U+02BB-02BC, U+02C6, U+02DA,
		U+02DC, U+0304, U+0308, U+0329, U+2000-206F, U+2074, U+20AC, U+2122,
		U+2191, U+2193, U+2212, U+2215, U+FEFF, U+FFFD;
}
@font-face {
	font-family: "Lato";
	font-style: normal;
	font-weight: 700;
	font-display: swap;
	src: url(https://fonts.gstatic.com/s/lato/v24/S6u9w4BMUTPHh6UVSwaPGR_p.woff2)
		format("woff2");
	unicode-range: U+0100-02AF, U+0304, U+0308, U+0329, U+1E00-1E9F, U+1EF2-1EFF,
		U+2020, U+20A0-20AB, U+20AD-20C0, U+2113, U+2C60-2C7F, U+A720-A7FF;
}
@font-face {
	font-family: "Lato";
	font-style: normal;
	font-weight: 700;
	font-display: swap;
	src: url(https://fonts.gstatic.com/s/lato/v24/S6u9w4BMUTPHh6UVSwiPGQ.woff2)
		format("woff2");
	unicode-range: U+0000-00FF, U+0131, U+0152-0153, U+02BB-02BC, U+02C6, U+02DA,
		U+02DC, U+0304, U+0308, U+0329, U+2000-206F, U+2074, U+20AC, U+2122,
		U+2191, U+2193, U+2212, U+2215, U+FEFF, U+FFFD;
}
@font-face {
	font-family: "Lato";
	font-style: normal;
	font-weight: 900;
	font-display: swap;
	src: url(https://fonts.gstatic.com/s/lato/v24/S6u9w4BMUTPHh50XSwaPGR_p.woff2)
		format("woff2");
	unicode-range: U+0100-02AF, U+0304, U+0308, U+0329, U+1E00-1E9F, U+1EF2-1EFF,
		U+2020, U+20A0-20AB, U+20AD-20C0, U+2113, U+2C60-2C7F, U+A720-A7FF;
}
@font-face {
	font-family: "Lato";
	font-style: normal;
	font-weight: 900;
	font-display: swap;
	src: url(https://fonts.gstatic.com/s/lato/v24/S6u9w4BMUTPHh50XSwiPGQ.woff2)
		format("woff2");
	unicode-range: U+0000-00FF, U+0131, U+0152-0153, U+02BB-02BC, U+02C6, U+02DA,
		U+02DC, U+0304, U+0308, U+0329, U+2000-206F, U+2074, U+20AC, U+2122,
		U+2191, U+2193, U+2212, U+2215, U+FEFF, U+FFFD;
}
@font-face {
	font-family: "Lato";
	font-style: italic;
	font-weight: 400;
	font-display: swap;
	src: url(https://fonts.gstatic.com/s/lato/v24/S6u8w4BMUTPHjxsAUi-qJCY.woff2)
		format("woff2");
	unicode-range: U+0100-02AF, U+0304, U+0308, U+0329, U+1E00-1E9F, U+1EF2-1EFF,
		U+2020, U+20A0-20AB, U+20AD-20C0, U+2113, U+2C60-2C7F, U+A720-A7FF;
}
@font-face {
	font-family: "Lato";
	font-style: italic;
	font-weight: 400;
	font-display: swap;
	src: url(https://fonts.gstatic.com/s/lato/v24/S6u8w4BMUTPHjxsAXC-q.woff2)
		format("woff2");
	unicode-range: U+0000-00FF, U+0131, U+0152-0153, U+02BB-02BC, U+02C6, U+02DA,
		U+02DC, U+0304, U+0308, U+0329, U+2000-206F, U+2074, U+20AC, U+2122,
		U+2191, U+2193, U+2212, U+2215, U+FEFF, U+FFFD;
}
@font-face {
	font-family: "Lato";
	font-style: italic;
	font-weight: 700;
	font-display: swap;
	src: url(https://fonts.gstatic.com/s/lato/v24/S6u_w4BMUTPHjxsI5wq_FQft1dw.woff2)
		format("woff2");
	unicode-range: U+0100-02AF, U+0304, U+0308, U+0329, U+1E00-1E9F, U+1EF2-1EFF,
		U+2020, U+20A0-20AB, U+20AD-20C0, U+2113, U+2C60-2C7F, U+A720-A7FF;
}
@font-face {
	font-family: "Lato";
	font-style: italic;
	font-weight: 700;
	font-display: swap;
	src: url(https://fonts.gstatic.com/s/lato/v24/S6u_w4BMUTPHjxsI5wq_Gwft.woff2)
		format("woff2");
	unicode-range: U+0000-00FF, U+0131, U+0152-0153, U+02BB-02BC, U+02C6, U+02DA,
		U+02DC, U+0304, U+0308, U+0329, U+2000-206F, U+2074, U+20AC, U+2122,
		U+2191, U+2193, U+2212, U+2215, U+FEFF, U+FFFD;
}
@font-face {
	font-family: "Lato";
	font-style: normal;
	font-weight: 400;
	font-display: swap;
	src: url(https://fonts.gstatic.com/s/lato/v24/S6uyw4BMUTPHjxAwXjeu.woff2)
		format("woff2");
	unicode-range: U+0100-02AF, U+0304, U+0308, U+0329, U+1E00-1E9F, U+1EF2-1EFF,
		U+2020, U+20A0-20AB, U+20AD-20C0, U+2113, U+2C60-2C7F, U+A720-A7FF;
}
@font-face {
	font-family: "Lato";
	font-style: normal;
	font-weight: 400;
	font-display: swap;
	src: url(https://fonts.gstatic.com/s/lato/v24/S6uyw4BMUTPHjx4wXg.woff2)
		format("woff2");
	unicode-range: U+0000-00FF, U+0131, U+0152-0153, U+02BB-02BC, U+02C6, U+02DA,
		U+02DC, U+0304, U+0308, U+0329, U+2000-206F, U+2074, U+20AC, U+2122,
		U+2191, U+2193, U+2212, U+2215, U+FEFF, U+FFFD;
}
@font-face {
	font-family: "Lato";
	font-style: normal;
	font-weight: 700;
	font-display: swap;
	src: url(https://fonts.gstatic.com/s/lato/v24/S6u9w4BMUTPHh6UVSwaPGR_p.woff2)
		format("woff2");
	unicode-range: U+0100-02AF, U+0304, U+0308, U+0329, U+1E00-1E9F, U+1EF2-1EFF,
		U+2020, U+20A0-20AB, U+20AD-20C0, U+2113, U+2C60-2C7F, U+A720-A7FF;
}
@font-face {
	font-family: "Lato";
	font-style: normal;
	font-weight: 700;
	font-display: swap;
	src: url(https://fonts.gstatic.com/s/lato/v24/S6u9w4BMUTPHh6UVSwiPGQ.woff2)
		format("woff2");
	unicode-range: U+0000-00FF, U+0131, U+0152-0153, U+02BB-02BC, U+02C6, U+02DA,
		U+02DC, U+0304, U+0308, U+0329, U+2000-206F, U+2074, U+20AC, U+2122,
		U+2191, U+2193, U+2212, U+2215, U+FEFF, U+FFFD;
}
@font-face {
	font-family: "Lato";
	font-style: normal;
	font-weight: 900;
	font-display: swap;
	src: url(https://fonts.gstatic.com/s/lato/v24/S6u9w4BMUTPHh50XSwaPGR_p.woff2)
		format("woff2");
	unicode-range: U+0100-02AF, U+0304, U+0308, U+0329, U+1E00-1E9F, U+1EF2-1EFF,
		U+2020, U+20A0-20AB, U+20AD-20C0, U+2113, U+2C60-2C7F, U+A720-A7FF;
}
@font-face {
	font-family: "Lato";
	font-style: normal;
	font-weight: 900;
	font-display: swap;
	src: url(https://fonts.gstatic.com/s/lato/v24/S6u9w4BMUTPHh50XSwiPGQ.woff2)
		format("woff2");
	unicode-range: U+0000-00FF, U+0131, U+0152-0153, U+02BB-02BC, U+02C6, U+02DA,
		U+02DC, U+0304, U+0308, U+0329, U+2000-206F, U+2074, U+20AC, U+2122,
		U+2191, U+2193, U+2212, U+2215, U+FEFF, U+FFFD;
}
@font-face {
	font-family: "Lato";
	font-style: italic;
	font-weight: 400;
	font-display: swap;
	src: url(https://fonts.gstatic.com/s/lato/v24/S6u8w4BMUTPHjxsAUi-qJCY.woff2)
		format("woff2");
	unicode-range: U+0100-02AF, U+0304, U+0308, U+0329, U+1E00-1E9F, U+1EF2-1EFF,
		U+2020, U+20A0-20AB, U+20AD-20C0, U+2113, U+2C60-2C7F, U+A720-A7FF;
}
@font-face {
	font-family: "Lato";
	font-style: italic;
	font-weight: 400;
	font-display: swap;
	src: url(https://fonts.gstatic.com/s/lato/v24/S6u8w4BMUTPHjxsAXC-q.woff2)
		format("woff2");
	unicode-range: U+0000-00FF, U+0131, U+0152-0153, U+02BB-02BC, U+02C6, U+02DA,
		U+02DC, U+0304, U+0308, U+0329, U+2000-206F, U+2074, U+20AC, U+2122,
		U+2191, U+2193, U+2212, U+2215, U+FEFF, U+FFFD;
}
@font-face {
	font-family: "Lato";
	font-style: italic;
	font-weight: 700;
	font-display: swap;
	src: url(https://fonts.gstatic.com/s/lato/v24/S6u_w4BMUTPHjxsI5wq_FQft1dw.woff2)
		format("woff2");
	unicode-range: U+0100-02AF, U+0304, U+0308, U+0329, U+1E00-1E9F, U+1EF2-1EFF,
		U+2020, U+20A0-20AB, U+20AD-20C0, U+2113, U+2C60-2C7F, U+A720-A7FF;
}
@font-face {
	font-family: "Lato";
	font-style: italic;
	font-weight: 700;
	font-display: swap;
	src: url(https://fonts.gstatic.com/s/lato/v24/S6u_w4BMUTPHjxsI5wq_Gwft.woff2)
		format("woff2");
	unicode-range: U+0000-00FF, U+0131, U+0152-0153, U+02BB-02BC, U+02C6, U+02DA,
		U+02DC, U+0304, U+0308, U+0329, U+2000-206F, U+2074, U+20AC, U+2122,
		U+2191, U+2193, U+2212, U+2215, U+FEFF, U+FFFD;
}
@font-face {
	font-family: "Lato";
	font-style: normal;
	font-weight: 400;
	font-display: swap;
	src: url(https://fonts.gstatic.com/s/lato/v24/S6uyw4BMUTPHjxAwXjeu.woff2)
		format("woff2");
	unicode-range: U+0100-02AF, U+0304, U+0308, U+0329, U+1E00-1E9F, U+1EF2-1EFF,
		U+2020, U+20A0-20AB, U+20AD-20C0, U+2113, U+2C60-2C7F, U+A720-A7FF;
}
@font-face {
	font-family: "Lato";
	font-style: normal;
	font-weight: 400;
	font-display: swap;
	src: url(https://fonts.gstatic.com/s/lato/v24/S6uyw4BMUTPHjx4wXg.woff2)
		format("woff2");
	unicode-range: U+0000-00FF, U+0131, U+0152-0153, U+02BB-02BC, U+02C6, U+02DA,
		U+02DC, U+0304, U+0308, U+0329, U+2000-206F, U+2074, U+20AC, U+2122,
		U+2191, U+2193, U+2212, U+2215, U+FEFF, U+FFFD;
}
@font-face {
	font-family: "Lato";
	font-style: normal;
	font-weight: 700;
	font-display: swap;
	src: url(https://fonts.gstatic.com/s/lato/v24/S6u9w4BMUTPHh6UVSwaPGR_p.woff2)
		format("woff2");
	unicode-range: U+0100-02AF, U+0304, U+0308, U+0329, U+1E00-1E9F, U+1EF2-1EFF,
		U+2020, U+20A0-20AB, U+20AD-20C0, U+2113, U+2C60-2C7F, U+A720-A7FF;
}
@font-face {
	font-family: "Lato";
	font-style: normal;
	font-weight: 700;
	font-display: swap;
	src: url(https://fonts.gstatic.com/s/lato/v24/S6u9w4BMUTPHh6UVSwiPGQ.woff2)
		format("woff2");
	unicode-range: U+0000-00FF, U+0131, U+0152-0153, U+02BB-02BC, U+02C6, U+02DA,
		U+02DC, U+0304, U+0308, U+0329, U+2000-206F, U+2074, U+20AC, U+2122,
		U+2191, U+2193, U+2212, U+2215, U+FEFF, U+FFFD;
}
@font-face {
	font-family: "Lato";
	font-style: normal;
	font-weight: 900;
	font-display: swap;
	src: url(https://fonts.gstatic.com/s/lato/v24/S6u9w4BMUTPHh50XSwaPGR_p.woff2)
		format("woff2");
	unicode-range: U+0100-02AF, U+0304, U+0308, U+0329, U+1E00-1E9F, U+1EF2-1EFF,
		U+2020, U+20A0-20AB, U+20AD-20C0, U+2113, U+2C60-2C7F, U+A720-A7FF;
}
@font-face {
	font-family: "Lato";
	font-style: normal;
	font-weight: 900;
	font-display: swap;
	src: url(https://fonts.gstatic.com/s/lato/v24/S6u9w4BMUTPHh50XSwiPGQ.woff2)
		format("woff2");
	unicode-range: U+0000-00FF, U+0131, U+0152-0153, U+02BB-02BC, U+02C6, U+02DA,
		U+02DC, U+0304, U+0308, U+0329, U+2000-206F, U+2074, U+20AC, U+2122,
		U+2191, U+2193, U+2212, U+2215, U+FEFF, U+FFFD;
}
@font-face {
	font-family: "Lato";
	font-style: italic;
	font-weight: 400;
	font-display: swap;
	src: url(https://fonts.gstatic.com/s/lato/v24/S6u8w4BMUTPHjxsAUi-qJCY.woff2)
		format("woff2");
	unicode-range: U+0100-02AF, U+0304, U+0308, U+0329, U+1E00-1E9F, U+1EF2-1EFF,
		U+2020, U+20A0-20AB, U+20AD-20C0, U+2113, U+2C60-2C7F, U+A720-A7FF;
}
@font-face {
	font-family: "Lato";
	font-style: italic;
	font-weight: 400;
	font-display: swap;
	src: url(https://fonts.gstatic.com/s/lato/v24/S6u8w4BMUTPHjxsAXC-q.woff2)
		format("woff2");
	unicode-range: U+0000-00FF, U+0131, U+0152-0153, U+02BB-02BC, U+02C6, U+02DA,
		U+02DC, U+0304, U+0308, U+0329, U+2000-206F, U+2074, U+20AC, U+2122,
		U+2191, U+2193, U+2212, U+2215, U+FEFF, U+FFFD;
}
@font-face {
	font-family: "Lato";
	font-style: italic;
	font-weight: 700;
	font-display: swap;
	src: url(https://fonts.gstatic.com/s/lato/v24/S6u_w4BMUTPHjxsI5wq_FQft1dw.woff2)
		format("woff2");
	unicode-range: U+0100-02AF, U+0304, U+0308, U+0329, U+1E00-1E9F, U+1EF2-1EFF,
		U+2020, U+20A0-20AB, U+20AD-20C0, U+2113, U+2C60-2C7F, U+A720-A7FF;
}
@font-face {
	font-family: "Lato";
	font-style: italic;
	font-weight: 700;
	font-display: swap;
	src: url(https://fonts.gstatic.com/s/lato/v24/S6u_w4BMUTPHjxsI5wq_Gwft.woff2)
		format("woff2");
	unicode-range: U+0000-00FF, U+0131, U+0152-0153, U+02BB-02BC, U+02C6, U+02DA,
		U+02DC, U+0304, U+0308, U+0329, U+2000-206F, U+2074, U+20AC, U+2122,
		U+2191, U+2193, U+2212, U+2215, U+FEFF, U+FFFD;
}
@font-face {
	font-family: "Lato";
	font-style: normal;
	font-weight: 400;
	font-display: swap;
	src: url(https://fonts.gstatic.com/s/lato/v24/S6uyw4BMUTPHjxAwXjeu.woff2)
		format("woff2");
	unicode-range: U+0100-02AF, U+0304, U+0308, U+0329, U+1E00-1E9F, U+1EF2-1EFF,
		U+2020, U+20A0-20AB, U+20AD-20C0, U+2113, U+2C60-2C7F, U+A720-A7FF;
}
@font-face {
	font-family: "Lato";
	font-style: normal;
	font-weight: 400;
	font-display: swap;
	src: url(https://fonts.gstatic.com/s/lato/v24/S6uyw4BMUTPHjx4wXg.woff2)
		format("woff2");
	unicode-range: U+0000-00FF, U+0131, U+0152-0153, U+02BB-02BC, U+02C6, U+02DA,
		U+02DC, U+0304, U+0308, U+0329, U+2000-206F, U+2074, U+20AC, U+2122,
		U+2191, U+2193, U+2212, U+2215, U+FEFF, U+FFFD;
}
@font-face {
	font-family: "Lato";
	font-style: normal;
	font-weight: 700;
	font-display: swap;
	src: url(https://fonts.gstatic.com/s/lato/v24/S6u9w4BMUTPHh6UVSwaPGR_p.woff2)
		format("woff2");
	unicode-range: U+0100-02AF, U+0304, U+0308, U+0329, U+1E00-1E9F, U+1EF2-1EFF,
		U+2020, U+20A0-20AB, U+20AD-20C0, U+2113, U+2C60-2C7F, U+A720-A7FF;
}
@font-face {
	font-family: "Lato";
	font-style: normal;
	font-weight: 700;
	font-display: swap;
	src: url(https://fonts.gstatic.com/s/lato/v24/S6u9w4BMUTPHh6UVSwiPGQ.woff2)
		format("woff2");
	unicode-range: U+0000-00FF, U+0131, U+0152-0153, U+02BB-02BC, U+02C6, U+02DA,
		U+02DC, U+0304, U+0308, U+0329, U+2000-206F, U+2074, U+20AC, U+2122,
		U+2191, U+2193, U+2212, U+2215, U+FEFF, U+FFFD;
}
@font-face {
	font-family: "Lato";
	font-style: normal;
	font-weight: 900;
	font-display: swap;
	src: url(https://fonts.gstatic.com/s/lato/v24/S6u9w4BMUTPHh50XSwaPGR_p.woff2)
		format("woff2");
	unicode-range: U+0100-02AF, U+0304, U+0308, U+0329, U+1E00-1E9F, U+1EF2-1EFF,
		U+2020, U+20A0-20AB, U+20AD-20C0, U+2113, U+2C60-2C7F, U+A720-A7FF;
}
@font-face {
	font-family: "Lato";
	font-style: normal;
	font-weight: 900;
	font-display: swap;
	src: url(https://fonts.gstatic.com/s/lato/v24/S6u9w4BMUTPHh50XSwiPGQ.woff2)
		format("woff2");
	unicode-range: U+0000-00FF, U+0131, U+0152-0153, U+02BB-02BC, U+02C6, U+02DA,
		U+02DC, U+0304, U+0308, U+0329, U+2000-206F, U+2074, U+20AC, U+2122,
		U+2191, U+2193, U+2212, U+2215, U+FEFF, U+FFFD;
}
@font-face {
	font-family: "Font Awesome 5 Free";
	font-style: normal;
	font-weight: 400;
	font-display: auto;
	src: url(https://use.fontawesome.com/releases/v5.8.1/webfonts/fa-regular-400.eot);
	src: url(https://use.fontawesome.com/releases/v5.8.1/webfonts/fa-regular-400.eot?#iefix)
			format("embedded-opentype"),
		url(https://use.fontawesome.com/releases/v5.8.1/webfonts/fa-regular-400.woff2)
			format("woff2"),
		url(https://use.fontawesome.com/releases/v5.8.1/webfonts/fa-regular-400.woff)
			format("woff"),
		url(https://use.fontawesome.com/releases/v5.8.1/webfonts/fa-regular-400.ttf)
			format("truetype"),
		url(https://use.fontawesome.com/releases/v5.8.1/webfonts/fa-regular-400.svg#fontawesome)
			format("svg");
}
@font-face {
	font-family: "Font Awesome 5 Free";
	font-style: normal;
	font-weight: 900;
	font-display: auto;
	src: url(https://use.fontawesome.com/releases/v5.8.1/webfonts/fa-solid-900.eot);
	src: url(https://use.fontawesome.com/releases/v5.8.1/webfonts/fa-solid-900.eot?#iefix)
			format("embedded-opentype"),
		url(https://use.fontawesome.com/releases/v5.8.1/webfonts/fa-solid-900.woff2)
			format("woff2"),
		url(https://use.fontawesome.com/releases/v5.8.1/webfonts/fa-solid-900.woff)
			format("woff"),
		url(https://use.fontawesome.com/releases/v5.8.1/webfonts/fa-solid-900.ttf)
			format("truetype"),
		url(https://use.fontawesome.com/releases/v5.8.1/webfonts/fa-solid-900.svg#fontawesome)
			format("svg");
}
@font-face {
	font-family: "Font Awesome 5 Free";
	font-style: normal;
	font-weight: 400;
	font-display: auto;
	src: url(https://use.fontawesome.com/releases/v5.8.1/webfonts/fa-regular-400.eot);
	src: url(https://use.fontawesome.com/releases/v5.8.1/webfonts/fa-regular-400.eot?#iefix)
			format("embedded-opentype"),
		url(https://use.fontawesome.com/releases/v5.8.1/webfonts/fa-regular-400.woff2)
			format("woff2"),
		url(https://use.fontawesome.com/releases/v5.8.1/webfonts/fa-regular-400.woff)
			format("woff"),
		url(https://use.fontawesome.com/releases/v5.8.1/webfonts/fa-regular-400.ttf)
			format("truetype"),
		url(https://use.fontawesome.com/releases/v5.8.1/webfonts/fa-regular-400.svg#fontawesome)
			format("svg");
}
@font-face {
	font-family: "Font Awesome 5 Free";
	font-style: normal;
	font-weight: 900;
	font-display: auto;
	src: url(https://use.fontawesome.com/releases/v5.8.1/webfonts/fa-solid-900.eot);
	src: url(https://use.fontawesome.com/releases/v5.8.1/webfonts/fa-solid-900.eot?#iefix)
			format("embedded-opentype"),
		url(https://use.fontawesome.com/releases/v5.8.1/webfonts/fa-solid-900.woff2)
			format("woff2"),
		url(https://use.fontawesome.com/releases/v5.8.1/webfonts/fa-solid-900.woff)
			format("woff"),
		url(https://use.fontawesome.com/releases/v5.8.1/webfonts/fa-solid-900.ttf)
			format("truetype"),
		url(https://use.fontawesome.com/releases/v5.8.1/webfonts/fa-solid-900.svg#fontawesome)
			format("svg");
}
@font-face {
	font-family: "Font Awesome 5 Free";
	font-style: normal;
	font-weight: 400;
	font-display: auto;
	src: url(https://use.fontawesome.com/releases/v5.8.1/webfonts/fa-regular-400.eot);
	src: url(https://use.fontawesome.com/releases/v5.8.1/webfonts/fa-regular-400.eot?#iefix)
			format("embedded-opentype"),
		url(https://use.fontawesome.com/releases/v5.8.1/webfonts/fa-regular-400.woff2)
			format("woff2"),
		url(https://use.fontawesome.com/releases/v5.8.1/webfonts/fa-regular-400.woff)
			format("woff"),
		url(https://use.fontawesome.com/releases/v5.8.1/webfonts/fa-regular-400.ttf)
			format("truetype"),
		url(https://use.fontawesome.com/releases/v5.8.1/webfonts/fa-regular-400.svg#fontawesome)
			format("svg");
}
@font-face {
	font-family: "Font Awesome 5 Free";
	font-style: normal;
	font-weight: 900;
	font-display: auto;
	src: url(https://use.fontawesome.com/releases/v5.8.1/webfonts/fa-solid-900.eot);
	src: url(https://use.fontawesome.com/releases/v5.8.1/webfonts/fa-solid-900.eot?#iefix)
			format("embedded-opentype"),
		url(https://use.fontawesome.com/releases/v5.8.1/webfonts/fa-solid-900.woff2)
			format("woff2"),
		url(https://use.fontawesome.com/releases/v5.8.1/webfonts/fa-solid-900.woff)
			format("woff"),
		url(https://use.fontawesome.com/releases/v5.8.1/webfonts/fa-solid-900.ttf)
			format("truetype"),
		url(https://use.fontawesome.com/releases/v5.8.1/webfonts/fa-solid-900.svg#fontawesome)
			format("svg");
}
@font-face {
	font-family: "Font Awesome 5 Free";
	font-style: normal;
	font-weight: 400;
	font-display: auto;
	src: url(https://use.fontawesome.com/releases/v5.8.1/webfonts/fa-regular-400.eot);
	src: url(https://use.fontawesome.com/releases/v5.8.1/webfonts/fa-regular-400.eot?#iefix)
			format("embedded-opentype"),
		url(https://use.fontawesome.com/releases/v5.8.1/webfonts/fa-regular-400.woff2)
			format("woff2"),
		url(https://use.fontawesome.com/releases/v5.8.1/webfonts/fa-regular-400.woff)
			format("woff"),
		url(https://use.fontawesome.com/releases/v5.8.1/webfonts/fa-regular-400.ttf)
			format("truetype"),
		url(https://use.fontawesome.com/releases/v5.8.1/webfonts/fa-regular-400.svg#fontawesome)
			format("svg");
}
@font-face {
	font-family: "Font Awesome 5 Free";
	font-style: normal;
	font-weight: 900;
	font-display: auto;
	src: url(https://use.fontawesome.com/releases/v5.8.1/webfonts/fa-solid-900.eot);
	src: url(https://use.fontawesome.com/releases/v5.8.1/webfonts/fa-solid-900.eot?#iefix)
			format("embedded-opentype"),
		url(https://use.fontawesome.com/releases/v5.8.1/webfonts/fa-solid-900.woff2)
			format("woff2"),
		url(https://use.fontawesome.com/releases/v5.8.1/webfonts/fa-solid-900.woff)
			format("woff"),
		url(https://use.fontawesome.com/releases/v5.8.1/webfonts/fa-solid-900.ttf)
			format("truetype"),
		url(https://use.fontawesome.com/releases/v5.8.1/webfonts/fa-solid-900.svg#fontawesome)
			format("svg");
}
