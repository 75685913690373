/*! CSS Used from: https://stackpath.bootstrapcdn.com/bootstrap/4.5.2/css/bootstrap.min.css */
*,
::after,
::before {
	box-sizing: border-box;
}
main,
nav {
	display: block;
}
ul {
	margin-top: 0;
	margin-bottom: 1rem;
}
a {
	color: #007bff;
	text-decoration: none;
	background-color: transparent;
}
a:hover {
	color: #0056b3;
	text-decoration: underline;
}
img {
	vertical-align: middle;
	border-style: none;
}
input {
	margin: 0;
	font-family: inherit;
	font-size: inherit;
	line-height: inherit;
}
input {
	overflow: visible;
}
.form-inline {
	display: -ms-flexbox;
	display: flex;
	-ms-flex-flow: row wrap;
	flex-flow: row wrap;
	-ms-flex-align: center;
	align-items: center;
}
.nav-link {
	display: block;
	padding: 0.5rem 1rem;
}
.nav-link:focus,
.nav-link:hover {
	text-decoration: none;
}
.navbar {
	position: relative;
	display: -ms-flexbox;
	display: flex;
	-ms-flex-wrap: wrap;
	flex-wrap: wrap;
	-ms-flex-align: center;
	align-items: center;
	-ms-flex-pack: justify;
	justify-content: space-between;
	padding: 0.5rem 1rem;
}
.navbar-brand {
	display: inline-block;
	padding-top: 0.3125rem;
	padding-bottom: 0.3125rem;
	margin-right: 1rem;
	font-size: 1.25rem;
	line-height: inherit;
	white-space: nowrap;
}
.navbar-brand:focus,
.navbar-brand:hover {
	text-decoration: none;
}
.navbar-nav {
	display: -ms-flexbox;
	display: flex;
	-ms-flex-direction: column;
	flex-direction: column;
	padding-left: 0;
	margin-bottom: 0;
	list-style: none;
}
.navbar-nav .nav-link {
	padding-right: 0;
	padding-left: 0;
}
.navbar-collapse {
	-ms-flex-preferred-size: 100%;
	flex-basis: 100%;
	-ms-flex-positive: 1;
	flex-grow: 1;
	-ms-flex-align: center;
	align-items: center;
}
.navbar-light .navbar-brand {
	color: rgba(0, 0, 0, 0.9);
}
.navbar-light .navbar-brand:focus,
.navbar-light .navbar-brand:hover {
	color: rgba(0, 0, 0, 0.9);
}
.navbar-light .navbar-nav .nav-link {
	color: rgba(0, 0, 0, 0.5);
}
.navbar-light .navbar-nav .nav-link:focus,
.navbar-light .navbar-nav .nav-link:hover {
	color: rgba(0, 0, 0, 0.7);
}
.bg-light {
	background-color: #f8f9fa !important;
}
.align-items-center {
	-ms-flex-align: center !important;
	align-items: center !important;
}
.pr-0 {
	padding-right: 0 !important;
}
.pl-0 {
	padding-left: 0 !important;
}
.ml-auto {
	margin-left: auto !important;
}
@media (min-width: 768px) {
	.ml-md-auto {
		margin-left: auto !important;
	}
}
@media print {
	*,
	::after,
	::before {
		text-shadow: none !important;
		box-shadow: none !important;
	}
	a:not(.btn) {
		text-decoration: underline;
	}
	img {
		page-break-inside: avoid;
	}
	.navbar {
		display: none;
	}
}
/*! CSS Used from: Embedded */
* {
	font-family: "Lato", sans-serif;
}
input {
	outline: none;
	resize: none;
	border-radius: 0;
	border: 1px solid #f5f5f5;
	background: white;
}
ul,
li {
	margin: 0;
	padding: 0;
	list-style: none;
}
a {
	text-decoration: none;
	cursor: pointer;
}
a:hover {
	text-decoration: none !important;
	color: #d31148;
}
a:active {
	color: #d31148;
}
a:focus {
	color: #d31148;
	outline: none;
}
input:disabled {
	background: #f5f5f5 !important;
}
a {
	text-decoration: none !important;
	color: #5d656d;
}
*:focus {
	outline: none !important;
}
ul {
	list-style-type: none;
	margin: 0;
	padding: 0;
}
.bg-light {
	background-color: #fff !important;
}
/*! CSS Used from: Embedded */
*,
::after,
::before {
	box-sizing: border-box;
}
main,
nav {
	display: block;
}
ul {
	margin-top: 0;
	margin-bottom: 1rem;
}
a {
	color: #007bff;
	text-decoration: none;
	background-color: transparent;
}
a:hover {
	color: #0056b3;
	text-decoration: underline;
}
img {
	vertical-align: middle;
	border-style: none;
}
input {
	margin: 0;
	font-family: inherit;
	font-size: inherit;
	line-height: inherit;
}
input {
	overflow: visible;
}
.form-inline {
	display: flex;
	flex-flow: row wrap;
	align-items: center;
}
.nav-link {
	display: block;
	padding: 0.5rem 1rem;
}
.nav-link:focus,
.nav-link:hover {
	text-decoration: none;
}
.navbar {
	position: relative;
	display: flex;
	flex-wrap: wrap;
	align-items: center;
	justify-content: space-between;
	padding: 0.5rem 1rem;
}
.navbar-brand {
	display: inline-block;
	padding-top: 0.3125rem;
	padding-bottom: 0.3125rem;
	margin-right: 1rem;
	font-size: 1.25rem;
	line-height: inherit;
	white-space: nowrap;
}
.navbar-brand:focus,
.navbar-brand:hover {
	text-decoration: none;
}
.navbar-nav {
	display: flex;
	flex-direction: column;
	padding-left: 0;
	margin-bottom: 0;
	list-style: none;
}
.navbar-nav .nav-link {
	padding-right: 0;
	padding-left: 0;
}
.navbar-collapse {
	flex-basis: 100%;
	flex-grow: 1;
	align-items: center;
}
.navbar-light .navbar-brand {
	color: rgba(0, 0, 0, 0.9);
}
.navbar-light .navbar-brand:focus,
.navbar-light .navbar-brand:hover {
	color: rgba(0, 0, 0, 0.9);
}
.navbar-light .navbar-nav .nav-link {
	color: rgba(0, 0, 0, 0.5);
}
.navbar-light .navbar-nav .nav-link:focus,
.navbar-light .navbar-nav .nav-link:hover {
	color: rgba(0, 0, 0, 0.7);
}
.bg-light {
	background-color: #f8f9fa !important;
}
.align-items-center {
	align-items: center !important;
}
.pr-0 {
	padding-right: 0 !important;
}
.pl-0 {
	padding-left: 0 !important;
}
.ml-auto {
	margin-left: auto !important;
}
@media (min-width: 768px) {
	.ml-md-auto {
		margin-left: auto !important;
	}
}
@media print {
	*,
	::after,
	::before {
		text-shadow: none !important;
		box-shadow: none !important;
	}
	a:not(.btn) {
		text-decoration: underline;
	}
	img {
		page-break-inside: avoid;
	}
	.navbar {
		display: none;
	}
}
/*! CSS Used from: Embedded */
/*! @import https://use.fontawesome.com/releases/v5.8.1/css/all.css */
.fas {
	-moz-osx-font-smoothing: grayscale;
	-webkit-font-smoothing: antialiased;
	display: inline-block;
	font-style: normal;
	font-variant: normal;
	text-rendering: auto;
	line-height: 1;
}
.fa-search:before {
	content: "\f002";
}
.fas {
	font-family: "Font Awesome 5 Free";
}
.fas {
	font-weight: 900;
}
/*! end @import */
/*! CSS Used from: Embedded */
#site-header.navbar {
	border-bottom: 1px solid #eaeaea;
	padding: 8px 40px !important;
	flex-flow: wrap;
}
@media (max-width: 1024px) {
	#site-header.navbar {
		display: flex;
	}
}
@media (max-width: 1220px) {
	#site-header.navbar {
		padding: 8px 15px !important;
	}
}
#site-header.navbar .navbar-brand img {
	max-height: 30px;
	max-width: 90px;
}
#site-header.navbar .sidenav-toggler {
	padding: 15px;
	padding-right: 0;
}
#site-header.navbar .navbar-collapse {
	flex-basis: auto;
}
#site-header.navbar .navbar-collapse ul.navbar-nav {
	align-items: center;
	flex-direction: row;
}
#site-header.navbar .navbar-collapse ul.navbar-nav .nav-item {
	text-align: left;
}
#site-header.navbar .navbar-collapse ul.navbar-nav .nav-item .nav-link {
	font-size: 14px;
	color: #303e47;
	padding: 0rem 1rem;
}
#site-header.navbar .navbar-collapse ul.navbar-nav .nav-item .nav-link:hover,
#site-header.navbar .navbar-collapse ul.navbar-nav .nav-item .nav-link:focus {
	color: #d31148;
}
#site-header.navbar
	.navbar-collapse
	ul.navbar-nav
	.nav-item
	.nav-link:active:not(.btn) {
	background: inherit;
}
#site-header.navbar
	.navbar-collapse
	ul.navbar-nav
	.nav-item
	.nav-link:not(.btn) {
	padding-top: 0 !important;
}
.header-search-wrapper {
	border-radius: 6px;
	padding-left: 8px;
	margin: 8px 0px;
	background: #fff;
	border: 1px solid #dddddd;
}
@media (max-width: 576px) {
	.header-search-wrapper {
		margin-top: 12px !important;
	}
}
.header-search-wrapper input {
	border: 0;
	background: none;
	font-size: 14.5px !important;
	height: 38px;
	width: calc(100% - 30px);
	outline: none;
}
@media (max-width: 1024px) {
	.header-search-wrapper input {
		font-size: 16px;
	}
}
.header-search-wrapper .header-search {
	color: #d31148;
	font-size: 14px;
	padding: 5px;
}
#header-container {
	padding-right: 0 !important;
}
.navbar-brand {
	height: auto !important;
}
.sidenav-toggler {
	cursor: pointer;
}
.sidenav-toggler .sidenav-toggler-inner,
.sidenav-toggler .sidenav-toggler-line {
	width: 18px;
	transition: all 0.15s ease;
}
.sidenav-toggler .sidenav-toggler-inner {
	position: relative;
}
.sidenav-toggler .sidenav-toggler-inner:before {
	position: absolute;
	top: -14px;
	left: -11px;
	width: 40px;
	height: 40px;
	content: "";
	transition: all 0.15s cubic-bezier(0.68, -0.55, 0.265, 1.55);
	transform: scale(0);
	border-radius: 50%;
}
.sidenav-toggler .sidenav-toggler-line {
	position: relative;
	display: block;
	height: 2px;
	background-color: #172b4d;
}
.sidenav-toggler .sidenav-toggler-line:not(:last-child) {
	margin-bottom: 3px;
}
@media (min-width: 945.01px) {
	#site-header #sidenav-toggle-btn {
		display: none;
	}
}
@media (max-width: 945.01px) {
	#top-menu {
		display: none;
	}
}
@media (min-width: 945.01px) {
	#top-menu {
		display: block;
	}
}
.header-box {
	max-width: 300px;
	width: 100%;
}
@media (min-width: 945.01px) and (max-width: 1024px) {
	.header-box {
		max-width: 225px !important;
	}
}
@media (max-width: 945.01px) {
	.header-box {
		width: 100%;
		max-width: none;
	}
}
@media (max-width: 1270px) {
	.navbar-expand-custom .navbar-nav#loggedin_menu {
		justify-content: right;
	}
}
@media (min-width: 1270px) {
	.navbar-expand-custom {
		flex-flow: row nowrap;
		justify-content: flex-start;
	}
	.navbar-expand-custom .navbar-nav {
		flex-direction: row;
	}
	.navbar-expand-custom .navbar-nav .nav-link {
		padding-right: 0.5rem;
		padding-left: 0.5rem;
	}
	.navbar-expand-custom .navbar-collapse {
		display: flex !important;
		flex-basis: auto;
	}
}
* {
	font-family: "Lato", sans-serif;
	letter-spacing: 0.6px;
}
*:focus {
	outline: none;
}
a {
	cursor: pointer;
	color: #5d656d;
	text-decoration: none;
}
a:hover {
	color: #d31148;
	text-decoration: none;
}
.bg-light {
	background-color: #fff !important;
}
.fas {
	font-family: "Font Awesome 5 Free" !important;
}
img {
	vertical-align: middle;
	border-style: none;
	border: 0;
	max-width: 100%;
}
/*! CSS Used from: Embedded */
*,
::after,
::before {
	box-sizing: border-box;
}
main,
nav {
	display: block;
}
ul {
	margin-top: 0;
	margin-bottom: 1rem;
}
a {
	text-decoration: none;
	background-color: transparent;
}
a:hover {
	color: #d31148;
	text-decoration: underline;
}
img {
	vertical-align: middle;
	border-style: none;
}
input {
	margin: 0;
	font-family: inherit;
	font-size: inherit;
	line-height: inherit;
}
input {
	overflow: visible;
}
.form-inline {
	display: flex;
	flex-flow: row wrap;
	align-items: center;
}
.nav-link {
	display: block;
	padding: 0.5rem 1rem;
}
.nav-link:focus,
.nav-link:hover {
	text-decoration: none;
}
.navbar {
	position: relative;
	display: flex;
	flex-wrap: wrap;
	align-items: center;
	justify-content: space-between;
	padding: 0.5rem 1rem;
}
.navbar-brand {
	display: inline-block;
	padding-top: 0.3125rem;
	padding-bottom: 0.3125rem;
	margin-right: 1rem;
	font-size: 1.25rem;
	line-height: inherit;
	white-space: nowrap;
}
.navbar-brand:focus,
.navbar-brand:hover {
	text-decoration: none;
}
.navbar-nav {
	display: flex;
	flex-direction: column;
	padding-left: 0;
	margin-bottom: 0;
	list-style: none;
}
.navbar-nav .nav-link {
	padding-right: 0;
	padding-left: 0;
}
.navbar-collapse {
	flex-basis: 100%;
	flex-grow: 1;
	align-items: center;
}
.navbar-light .navbar-brand {
	color: rgba(0, 0, 0, 0.9);
}
.navbar-light .navbar-brand:focus,
.navbar-light .navbar-brand:hover {
	color: rgba(0, 0, 0, 0.9);
}
.navbar-light .navbar-nav .nav-link {
	color: rgba(0, 0, 0, 0.5);
}
.navbar-light .navbar-nav .nav-link:focus,
.navbar-light .navbar-nav .nav-link:hover {
	color: rgba(0, 0, 0, 0.7);
}
.bg-light {
	background-color: #f8f9fa !important;
}
.align-items-center {
	align-items: center !important;
}
.pr-0 {
	padding-right: 0 !important;
}
.pl-0 {
	padding-left: 0 !important;
}
.ml-auto {
	margin-left: auto !important;
}
@media (min-width: 768px) {
	.ml-md-auto {
		margin-left: auto !important;
	}
}
@media print {
	*,
	::after,
	::before {
		text-shadow: none !important;
		box-shadow: none !important;
	}
	a:not(.btn) {
		text-decoration: underline;
	}
	img {
		page-break-inside: avoid;
	}
	.navbar {
		display: none;
	}
}
/*! CSS Used fontfaces */
@font-face {
	font-family: "Lato";
	font-style: italic;
	font-weight: 400;
	font-display: swap;
	src: url(https://fonts.gstatic.com/s/lato/v24/S6u8w4BMUTPHjxsAUi-qJCY.woff2)
		format("woff2");
	unicode-range: U+0100-02AF, U+0304, U+0308, U+0329, U+1E00-1E9F, U+1EF2-1EFF,
		U+2020, U+20A0-20AB, U+20AD-20C0, U+2113, U+2C60-2C7F, U+A720-A7FF;
}
@font-face {
	font-family: "Lato";
	font-style: italic;
	font-weight: 400;
	font-display: swap;
	src: url(https://fonts.gstatic.com/s/lato/v24/S6u8w4BMUTPHjxsAXC-q.woff2)
		format("woff2");
	unicode-range: U+0000-00FF, U+0131, U+0152-0153, U+02BB-02BC, U+02C6, U+02DA,
		U+02DC, U+0304, U+0308, U+0329, U+2000-206F, U+2074, U+20AC, U+2122,
		U+2191, U+2193, U+2212, U+2215, U+FEFF, U+FFFD;
}
@font-face {
	font-family: "Lato";
	font-style: italic;
	font-weight: 700;
	font-display: swap;
	src: url(https://fonts.gstatic.com/s/lato/v24/S6u_w4BMUTPHjxsI5wq_FQft1dw.woff2)
		format("woff2");
	unicode-range: U+0100-02AF, U+0304, U+0308, U+0329, U+1E00-1E9F, U+1EF2-1EFF,
		U+2020, U+20A0-20AB, U+20AD-20C0, U+2113, U+2C60-2C7F, U+A720-A7FF;
}
@font-face {
	font-family: "Lato";
	font-style: italic;
	font-weight: 700;
	font-display: swap;
	src: url(https://fonts.gstatic.com/s/lato/v24/S6u_w4BMUTPHjxsI5wq_Gwft.woff2)
		format("woff2");
	unicode-range: U+0000-00FF, U+0131, U+0152-0153, U+02BB-02BC, U+02C6, U+02DA,
		U+02DC, U+0304, U+0308, U+0329, U+2000-206F, U+2074, U+20AC, U+2122,
		U+2191, U+2193, U+2212, U+2215, U+FEFF, U+FFFD;
}
@font-face {
	font-family: "Lato";
	font-style: normal;
	font-weight: 400;
	font-display: swap;
	src: url(https://fonts.gstatic.com/s/lato/v24/S6uyw4BMUTPHjxAwXjeu.woff2)
		format("woff2");
	unicode-range: U+0100-02AF, U+0304, U+0308, U+0329, U+1E00-1E9F, U+1EF2-1EFF,
		U+2020, U+20A0-20AB, U+20AD-20C0, U+2113, U+2C60-2C7F, U+A720-A7FF;
}
@font-face {
	font-family: "Lato";
	font-style: normal;
	font-weight: 400;
	font-display: swap;
	src: url(https://fonts.gstatic.com/s/lato/v24/S6uyw4BMUTPHjx4wXg.woff2)
		format("woff2");
	unicode-range: U+0000-00FF, U+0131, U+0152-0153, U+02BB-02BC, U+02C6, U+02DA,
		U+02DC, U+0304, U+0308, U+0329, U+2000-206F, U+2074, U+20AC, U+2122,
		U+2191, U+2193, U+2212, U+2215, U+FEFF, U+FFFD;
}
@font-face {
	font-family: "Lato";
	font-style: normal;
	font-weight: 700;
	font-display: swap;
	src: url(https://fonts.gstatic.com/s/lato/v24/S6u9w4BMUTPHh6UVSwaPGR_p.woff2)
		format("woff2");
	unicode-range: U+0100-02AF, U+0304, U+0308, U+0329, U+1E00-1E9F, U+1EF2-1EFF,
		U+2020, U+20A0-20AB, U+20AD-20C0, U+2113, U+2C60-2C7F, U+A720-A7FF;
}
@font-face {
	font-family: "Lato";
	font-style: normal;
	font-weight: 700;
	font-display: swap;
	src: url(https://fonts.gstatic.com/s/lato/v24/S6u9w4BMUTPHh6UVSwiPGQ.woff2)
		format("woff2");
	unicode-range: U+0000-00FF, U+0131, U+0152-0153, U+02BB-02BC, U+02C6, U+02DA,
		U+02DC, U+0304, U+0308, U+0329, U+2000-206F, U+2074, U+20AC, U+2122,
		U+2191, U+2193, U+2212, U+2215, U+FEFF, U+FFFD;
}
@font-face {
	font-family: "Lato";
	font-style: normal;
	font-weight: 900;
	font-display: swap;
	src: url(https://fonts.gstatic.com/s/lato/v24/S6u9w4BMUTPHh50XSwaPGR_p.woff2)
		format("woff2");
	unicode-range: U+0100-02AF, U+0304, U+0308, U+0329, U+1E00-1E9F, U+1EF2-1EFF,
		U+2020, U+20A0-20AB, U+20AD-20C0, U+2113, U+2C60-2C7F, U+A720-A7FF;
}
@font-face {
	font-family: "Lato";
	font-style: normal;
	font-weight: 900;
	font-display: swap;
	src: url(https://fonts.gstatic.com/s/lato/v24/S6u9w4BMUTPHh50XSwiPGQ.woff2)
		format("woff2");
	unicode-range: U+0000-00FF, U+0131, U+0152-0153, U+02BB-02BC, U+02C6, U+02DA,
		U+02DC, U+0304, U+0308, U+0329, U+2000-206F, U+2074, U+20AC, U+2122,
		U+2191, U+2193, U+2212, U+2215, U+FEFF, U+FFFD;
}
@font-face {
	font-family: "Font Awesome 5 Free";
	font-style: normal;
	font-weight: 400;
	font-display: auto;
	src: url(https://use.fontawesome.com/releases/v5.8.1/webfonts/fa-regular-400.eot);
	src: url(https://use.fontawesome.com/releases/v5.8.1/webfonts/fa-regular-400.eot?#iefix)
			format("embedded-opentype"),
		url(https://use.fontawesome.com/releases/v5.8.1/webfonts/fa-regular-400.woff2)
			format("woff2"),
		url(https://use.fontawesome.com/releases/v5.8.1/webfonts/fa-regular-400.woff)
			format("woff"),
		url(https://use.fontawesome.com/releases/v5.8.1/webfonts/fa-regular-400.ttf)
			format("truetype"),
		url(https://use.fontawesome.com/releases/v5.8.1/webfonts/fa-regular-400.svg#fontawesome)
			format("svg");
}
@font-face {
	font-family: "Font Awesome 5 Free";
	font-style: normal;
	font-weight: 900;
	font-display: auto;
	src: url(https://use.fontawesome.com/releases/v5.8.1/webfonts/fa-solid-900.eot);
	src: url(https://use.fontawesome.com/releases/v5.8.1/webfonts/fa-solid-900.eot?#iefix)
			format("embedded-opentype"),
		url(https://use.fontawesome.com/releases/v5.8.1/webfonts/fa-solid-900.woff2)
			format("woff2"),
		url(https://use.fontawesome.com/releases/v5.8.1/webfonts/fa-solid-900.woff)
			format("woff"),
		url(https://use.fontawesome.com/releases/v5.8.1/webfonts/fa-solid-900.ttf)
			format("truetype"),
		url(https://use.fontawesome.com/releases/v5.8.1/webfonts/fa-solid-900.svg#fontawesome)
			format("svg");
}
