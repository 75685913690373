/*! CSS Used from: https://stackpath.bootstrapcdn.com/bootstrap/4.5.2/css/bootstrap.min.css */
*,
::after,
::before {
	box-sizing: border-box;
}
footer,
main {
	display: block;
}
h6 {
	margin-top: 0;
	margin-bottom: 0.5rem;
}
p {
	margin-top: 0;
	margin-bottom: 1rem;
}
a {
	color: #007bff;
	text-decoration: none;
	background-color: transparent;
}
a:hover {
	color: #0056b3;
	text-decoration: underline;
}
img {
	vertical-align: middle;
	border-style: none;
}
h6 {
	margin-bottom: 0.5rem;
	font-weight: 500;
	line-height: 1.2;
}
h6 {
	font-size: 1rem;
}
.row {
	display: -ms-flexbox;
	display: flex;
	-ms-flex-wrap: wrap;
	flex-wrap: wrap;
	margin-right: -15px;
	margin-left: -15px;
}
.col,
.col-6,
.col-lg-12,
.col-lg-6,
.col-md-6,
.col-sm-12,
.col-sm-4,
.col-sm-8,
.col-xl-4 {
	position: relative;
	width: 100%;
	padding-right: 15px;
	padding-left: 15px;
}
.col {
	-ms-flex-preferred-size: 0;
	flex-basis: 0;
	-ms-flex-positive: 1;
	flex-grow: 1;
	max-width: 100%;
}
.col-6 {
	-ms-flex: 0 0 50%;
	flex: 0 0 50%;
	max-width: 50%;
}
@media (min-width: 576px) {
	.col-sm-4 {
		-ms-flex: 0 0 33.333333%;
		flex: 0 0 33.333333%;
		max-width: 33.333333%;
	}
	.col-sm-8 {
		-ms-flex: 0 0 66.666667%;
		flex: 0 0 66.666667%;
		max-width: 66.666667%;
	}
	.col-sm-12 {
		-ms-flex: 0 0 100%;
		flex: 0 0 100%;
		max-width: 100%;
	}
}
@media (min-width: 768px) {
	.col-md-6 {
		-ms-flex: 0 0 50%;
		flex: 0 0 50%;
		max-width: 50%;
	}
}
@media (min-width: 992px) {
	.col-lg-6 {
		-ms-flex: 0 0 50%;
		flex: 0 0 50%;
		max-width: 50%;
	}
	.col-lg-12 {
		-ms-flex: 0 0 100%;
		flex: 0 0 100%;
		max-width: 100%;
	}
}
@media (min-width: 1200px) {
	.col-xl-4 {
		-ms-flex: 0 0 33.333333%;
		flex: 0 0 33.333333%;
		max-width: 33.333333%;
	}
}
@media (min-width: 576px) {
	.d-sm-none {
		display: none !important;
	}
}
@media (min-width: 768px) {
	.d-md-block {
		display: block !important;
	}
}
.mt-4 {
	margin-top: 1.5rem !important;
}
@media (min-width: 576px) {
	.mt-sm-0 {
		margin-top: 0 !important;
	}
}
@media print {
	*,
	::after,
	::before {
		text-shadow: none !important;
		box-shadow: none !important;
	}
	a:not(.btn) {
		text-decoration: underline;
	}
	img {
		page-break-inside: avoid;
	}
	p {
		orphans: 3;
		widows: 3;
	}
}
/*! CSS Used from: Embedded */
* {
	font-family: "Lato", sans-serif;
}
h6 {
	font-family: "Lato", sans-serif !important;
}
p {
	margin: 0;
	padding: 0;
}
h6 {
	font-weight: normal;
	margin: 0;
	padding: 0;
}
a {
	text-decoration: none;
	cursor: pointer;
}
a:hover {
	text-decoration: none !important;
	color: #d31148;
}
a:active {
	color: #d31148;
}
a:focus {
	color: #d31148;
	outline: none;
}
p {
	font-size: 14px;
}
a {
	text-decoration: none !important;
	color: #5d656d;
}
*:focus {
	outline: none !important;
}
/*! CSS Used from: Embedded */
*,
::after,
::before {
	box-sizing: border-box;
}
footer,
main {
	display: block;
}
h6 {
	margin-top: 0;
	margin-bottom: 0.5rem;
}
p {
	margin-top: 0;
	margin-bottom: 1rem;
}
a {
	color: #007bff;
	text-decoration: none;
	background-color: transparent;
}
a:hover {
	color: #0056b3;
	text-decoration: underline;
}
img {
	vertical-align: middle;
	border-style: none;
}
h6 {
	margin-bottom: 0.5rem;
	font-weight: 500;
	line-height: 1.2;
}
h6 {
	font-size: 1rem;
}
.row {
	display: flex;
	flex-wrap: wrap;
	margin-right: -15px;
	margin-left: -15px;
}
.col,
.col-6,
.col-lg-12,
.col-lg-6,
.col-md-6,
.col-sm-12,
.col-sm-4,
.col-sm-8,
.col-xl-4 {
	position: relative;
	width: 100%;
	padding-right: 15px;
	padding-left: 15px;
}
.col {
	flex-basis: 0;
	flex-grow: 1;
	max-width: 100%;
}
.col-6 {
	flex: 0 0 50%;
	max-width: 50%;
}
@media (min-width: 576px) {
	.col-sm-4 {
		flex: 0 0 33.333333%;
		max-width: 33.333333%;
	}
	.col-sm-8 {
		flex: 0 0 66.666667%;
		max-width: 66.666667%;
	}
	.col-sm-12 {
		flex: 0 0 100%;
		max-width: 100%;
	}
}
@media (min-width: 768px) {
	.col-md-6 {
		flex: 0 0 50%;
		max-width: 50%;
	}
}
@media (min-width: 992px) {
	.col-lg-6 {
		flex: 0 0 50%;
		max-width: 50%;
	}
	.col-lg-12 {
		flex: 0 0 100%;
		max-width: 100%;
	}
}
@media (min-width: 1200px) {
	.col-xl-4 {
		flex: 0 0 33.333333%;
		max-width: 33.333333%;
	}
}
@media (min-width: 576px) {
	.d-sm-none {
		display: none !important;
	}
}
@media (min-width: 768px) {
	.d-md-block {
		display: block !important;
	}
}
.mt-4 {
	margin-top: 1.5rem !important;
}
@media (min-width: 576px) {
	.mt-sm-0 {
		margin-top: 0 !important;
	}
}
@media print {
	*,
	::after,
	::before {
		text-shadow: none !important;
		box-shadow: none !important;
	}
	a:not(.btn) {
		text-decoration: underline;
	}
	img {
		page-break-inside: avoid;
	}
	p {
		orphans: 3;
		widows: 3;
	}
}
/*! CSS Used from: Embedded */
/*! @import https://use.fontawesome.com/releases/v5.8.1/css/all.css */
.fab,
.fas {
	-moz-osx-font-smoothing: grayscale;
	-webkit-font-smoothing: antialiased;
	display: inline-block;
	font-style: normal;
	font-variant: normal;
	text-rendering: auto;
	line-height: 1;
}
.fa-envelope:before {
	content: "\f0e0";
}
.fa-facebook-f:before {
	content: "\f39e";
}
.fa-linkedin-in:before {
	content: "\f0e1";
}
.fa-twitter:before {
	content: "\f099";
}
.fab {
	font-family: "Font Awesome 5 Brands";
}
.fas {
	font-family: "Font Awesome 5 Free";
}
.fas {
	font-weight: 900;
}
/*! end @import */
/*! CSS Used from: Embedded */
@media (max-width: 575px) {
	.hidden-lg-down {
		display: none !important;
	}
}
@media (min-width: 576px) and (max-width: 767px) {
	.hidden-lg-down {
		display: none !important;
	}
}
@media (min-width: 768px) and (max-width: 991px) {
	.hidden-lg-down {
		display: none !important;
	}
}
@media (min-width: 992px) and (max-width: 1199px) {
	.hidden-lg-down {
		display: none !important;
	}
}
@media (min-width: 1200px) {
	.hidden-xl-up {
		display: none !important;
	}
}
footer {
	padding: 40px;
	background: white;
	margin-top: 50px;
}
footer h6 {
	color: #d31148;
	margin-bottom: 12px;
	font-size: 14.5px;
}
footer .footer-header img {
	max-width: 97px;
}
footer .footer-header .footer-terms {
	margin-top: 10px;
}
footer .footer-header .footer-terms p {
	font-size: 12.5px;
	letter-spacing: 0.5px;
	margin-bottom: 2px;
}
@media (max-width: 1199px) {
	footer .footer-header {
		margin-bottom: 20px;
	}
}
@media (max-width: 600px) {
	footer .footer-header {
		margin-bottom: 25px;
	}
}
footer .footer-links a {
	display: block;
	font-size: 14.5px;
}
footer .footer-links a:not(:last-child) {
	margin-bottom: 5px;
}
@media (max-width: 600px) {
	footer {
		padding: 20px;
	}
	footer .footer-links {
		margin-top: 10px;
	}
}
* {
	font-family: "Lato", sans-serif;
	letter-spacing: 0.6px;
}
*:focus {
	outline: none;
}
a {
	cursor: pointer;
	color: #5d656d;
	text-decoration: none;
}
a:hover {
	color: #d31148;
	text-decoration: none;
}
p {
	margin-bottom: 0;
	font-size: 14px;
}
.fas {
	font-family: "Font Awesome 5 Free" !important;
}
.fab {
	font-family: "Font Awesome 5 Brands" !important;
}
img {
	vertical-align: middle;
	border-style: none;
	border: 0;
	max-width: 100%;
}
.social-tab {
	display: flex;
	margin-top: 15px;
}
.social-tab a:last-child i {
	margin-right: 0;
}
.social-tab i {
	border: 1px solid #e7e7e7;
	border-radius: 50%;
	width: 30px;
	height: 30px;
	margin-right: 10px;
	font-size: 12px;
	color: #748594;
	display: flex;
	align-items: center;
	justify-content: center;
	transition: 0.5s ease;
}
.social-tab i:hover {
	border: 1px solid #d31148;
	color: #d31148;
}
.row {
	display: flex;
	flex-wrap: wrap;
	margin-right: -15px;
	margin-left: -15px;
}
/*! CSS Used from: Embedded */
*,
::after,
::before {
	box-sizing: border-box;
}
footer,
main {
	display: block;
}
h6 {
	margin-top: 0;
	margin-bottom: 0.5rem;
}
p {
	margin-top: 0;
	margin-bottom: 1rem;
}
a {
	text-decoration: none;
	background-color: transparent;
}
a:hover {
	color: #d31148;
	text-decoration: underline;
}
img {
	vertical-align: middle;
	border-style: none;
}
h6 {
	margin-bottom: 0.5rem;
	font-weight: 500;
	line-height: 1.2;
}
h6 {
	font-size: 1rem;
}
.row {
	display: flex;
	flex-wrap: wrap;
	margin-right: -15px;
	margin-left: -15px;
}
.col,
.col-6,
.col-lg-12,
.col-lg-6,
.col-md-6,
.col-sm-12,
.col-sm-4,
.col-sm-8,
.col-xl-4 {
	position: relative;
	width: 100%;
	padding-right: 15px;
	padding-left: 15px;
}
.col {
	flex-basis: 0;
	flex-grow: 1;
	max-width: 100%;
}
.col-6 {
	flex: 0 0 50%;
	max-width: 50%;
}
@media (min-width: 576px) {
	.col-sm-4 {
		flex: 0 0 33.333333%;
		max-width: 33.333333%;
	}
	.col-sm-8 {
		flex: 0 0 66.666667%;
		max-width: 66.666667%;
	}
	.col-sm-12 {
		flex: 0 0 100%;
		max-width: 100%;
	}
}
@media (min-width: 768px) {
	.col-md-6 {
		flex: 0 0 50%;
		max-width: 50%;
	}
}
@media (min-width: 992px) {
	.col-lg-6 {
		flex: 0 0 50%;
		max-width: 50%;
	}
	.col-lg-12 {
		flex: 0 0 100%;
		max-width: 100%;
	}
}
@media (min-width: 1200px) {
	.col-xl-4 {
		flex: 0 0 33.333333%;
		max-width: 33.333333%;
	}
}
@media (min-width: 576px) {
	.d-sm-none {
		display: none !important;
	}
}
@media (min-width: 768px) {
	.d-md-block {
		display: block !important;
	}
}
.mt-4 {
	margin-top: 1.5rem !important;
}
@media (min-width: 576px) {
	.mt-sm-0 {
		margin-top: 0 !important;
	}
}
@media print {
	*,
	::after,
	::before {
		text-shadow: none !important;
		box-shadow: none !important;
	}
	a:not(.btn) {
		text-decoration: underline;
	}
	img {
		page-break-inside: avoid;
	}
	p {
		orphans: 3;
		widows: 3;
	}
}
/*! CSS Used fontfaces */
@font-face {
	font-family: "Lato";
	font-style: italic;
	font-weight: 400;
	font-display: swap;
	src: url(https://fonts.gstatic.com/s/lato/v24/S6u8w4BMUTPHjxsAUi-qJCY.woff2)
		format("woff2");
	unicode-range: U+0100-02AF, U+0304, U+0308, U+0329, U+1E00-1E9F, U+1EF2-1EFF,
		U+2020, U+20A0-20AB, U+20AD-20C0, U+2113, U+2C60-2C7F, U+A720-A7FF;
}
@font-face {
	font-family: "Lato";
	font-style: italic;
	font-weight: 400;
	font-display: swap;
	src: url(https://fonts.gstatic.com/s/lato/v24/S6u8w4BMUTPHjxsAXC-q.woff2)
		format("woff2");
	unicode-range: U+0000-00FF, U+0131, U+0152-0153, U+02BB-02BC, U+02C6, U+02DA,
		U+02DC, U+0304, U+0308, U+0329, U+2000-206F, U+2074, U+20AC, U+2122,
		U+2191, U+2193, U+2212, U+2215, U+FEFF, U+FFFD;
}
@font-face {
	font-family: "Lato";
	font-style: italic;
	font-weight: 700;
	font-display: swap;
	src: url(https://fonts.gstatic.com/s/lato/v24/S6u_w4BMUTPHjxsI5wq_FQft1dw.woff2)
		format("woff2");
	unicode-range: U+0100-02AF, U+0304, U+0308, U+0329, U+1E00-1E9F, U+1EF2-1EFF,
		U+2020, U+20A0-20AB, U+20AD-20C0, U+2113, U+2C60-2C7F, U+A720-A7FF;
}
@font-face {
	font-family: "Lato";
	font-style: italic;
	font-weight: 700;
	font-display: swap;
	src: url(https://fonts.gstatic.com/s/lato/v24/S6u_w4BMUTPHjxsI5wq_Gwft.woff2)
		format("woff2");
	unicode-range: U+0000-00FF, U+0131, U+0152-0153, U+02BB-02BC, U+02C6, U+02DA,
		U+02DC, U+0304, U+0308, U+0329, U+2000-206F, U+2074, U+20AC, U+2122,
		U+2191, U+2193, U+2212, U+2215, U+FEFF, U+FFFD;
}
@font-face {
	font-family: "Lato";
	font-style: normal;
	font-weight: 400;
	font-display: swap;
	src: url(https://fonts.gstatic.com/s/lato/v24/S6uyw4BMUTPHjxAwXjeu.woff2)
		format("woff2");
	unicode-range: U+0100-02AF, U+0304, U+0308, U+0329, U+1E00-1E9F, U+1EF2-1EFF,
		U+2020, U+20A0-20AB, U+20AD-20C0, U+2113, U+2C60-2C7F, U+A720-A7FF;
}
@font-face {
	font-family: "Lato";
	font-style: normal;
	font-weight: 400;
	font-display: swap;
	src: url(https://fonts.gstatic.com/s/lato/v24/S6uyw4BMUTPHjx4wXg.woff2)
		format("woff2");
	unicode-range: U+0000-00FF, U+0131, U+0152-0153, U+02BB-02BC, U+02C6, U+02DA,
		U+02DC, U+0304, U+0308, U+0329, U+2000-206F, U+2074, U+20AC, U+2122,
		U+2191, U+2193, U+2212, U+2215, U+FEFF, U+FFFD;
}
@font-face {
	font-family: "Lato";
	font-style: normal;
	font-weight: 700;
	font-display: swap;
	src: url(https://fonts.gstatic.com/s/lato/v24/S6u9w4BMUTPHh6UVSwaPGR_p.woff2)
		format("woff2");
	unicode-range: U+0100-02AF, U+0304, U+0308, U+0329, U+1E00-1E9F, U+1EF2-1EFF,
		U+2020, U+20A0-20AB, U+20AD-20C0, U+2113, U+2C60-2C7F, U+A720-A7FF;
}
@font-face {
	font-family: "Lato";
	font-style: normal;
	font-weight: 700;
	font-display: swap;
	src: url(https://fonts.gstatic.com/s/lato/v24/S6u9w4BMUTPHh6UVSwiPGQ.woff2)
		format("woff2");
	unicode-range: U+0000-00FF, U+0131, U+0152-0153, U+02BB-02BC, U+02C6, U+02DA,
		U+02DC, U+0304, U+0308, U+0329, U+2000-206F, U+2074, U+20AC, U+2122,
		U+2191, U+2193, U+2212, U+2215, U+FEFF, U+FFFD;
}
@font-face {
	font-family: "Lato";
	font-style: normal;
	font-weight: 900;
	font-display: swap;
	src: url(https://fonts.gstatic.com/s/lato/v24/S6u9w4BMUTPHh50XSwaPGR_p.woff2)
		format("woff2");
	unicode-range: U+0100-02AF, U+0304, U+0308, U+0329, U+1E00-1E9F, U+1EF2-1EFF,
		U+2020, U+20A0-20AB, U+20AD-20C0, U+2113, U+2C60-2C7F, U+A720-A7FF;
}
@font-face {
	font-family: "Lato";
	font-style: normal;
	font-weight: 900;
	font-display: swap;
	src: url(https://fonts.gstatic.com/s/lato/v24/S6u9w4BMUTPHh50XSwiPGQ.woff2)
		format("woff2");
	unicode-range: U+0000-00FF, U+0131, U+0152-0153, U+02BB-02BC, U+02C6, U+02DA,
		U+02DC, U+0304, U+0308, U+0329, U+2000-206F, U+2074, U+20AC, U+2122,
		U+2191, U+2193, U+2212, U+2215, U+FEFF, U+FFFD;
}
@font-face {
	font-family: "Font Awesome 5 Brands";
	font-style: normal;
	font-weight: normal;
	font-display: auto;
	src: url(https://use.fontawesome.com/releases/v5.8.1/webfonts/fa-brands-400.eot);
	src: url(https://use.fontawesome.com/releases/v5.8.1/webfonts/fa-brands-400.eot?#iefix)
			format("embedded-opentype"),
		url(https://use.fontawesome.com/releases/v5.8.1/webfonts/fa-brands-400.woff2)
			format("woff2"),
		url(https://use.fontawesome.com/releases/v5.8.1/webfonts/fa-brands-400.woff)
			format("woff"),
		url(https://use.fontawesome.com/releases/v5.8.1/webfonts/fa-brands-400.ttf)
			format("truetype"),
		url(https://use.fontawesome.com/releases/v5.8.1/webfonts/fa-brands-400.svg#fontawesome)
			format("svg");
}
@font-face {
	font-family: "Font Awesome 5 Free";
	font-style: normal;
	font-weight: 400;
	font-display: auto;
	src: url(https://use.fontawesome.com/releases/v5.8.1/webfonts/fa-regular-400.eot);
	src: url(https://use.fontawesome.com/releases/v5.8.1/webfonts/fa-regular-400.eot?#iefix)
			format("embedded-opentype"),
		url(https://use.fontawesome.com/releases/v5.8.1/webfonts/fa-regular-400.woff2)
			format("woff2"),
		url(https://use.fontawesome.com/releases/v5.8.1/webfonts/fa-regular-400.woff)
			format("woff"),
		url(https://use.fontawesome.com/releases/v5.8.1/webfonts/fa-regular-400.ttf)
			format("truetype"),
		url(https://use.fontawesome.com/releases/v5.8.1/webfonts/fa-regular-400.svg#fontawesome)
			format("svg");
}
@font-face {
	font-family: "Font Awesome 5 Free";
	font-style: normal;
	font-weight: 900;
	font-display: auto;
	src: url(https://use.fontawesome.com/releases/v5.8.1/webfonts/fa-solid-900.eot);
	src: url(https://use.fontawesome.com/releases/v5.8.1/webfonts/fa-solid-900.eot?#iefix)
			format("embedded-opentype"),
		url(https://use.fontawesome.com/releases/v5.8.1/webfonts/fa-solid-900.woff2)
			format("woff2"),
		url(https://use.fontawesome.com/releases/v5.8.1/webfonts/fa-solid-900.woff)
			format("woff"),
		url(https://use.fontawesome.com/releases/v5.8.1/webfonts/fa-solid-900.ttf)
			format("truetype"),
		url(https://use.fontawesome.com/releases/v5.8.1/webfonts/fa-solid-900.svg#fontawesome)
			format("svg");
}
