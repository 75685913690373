@import url(https://use.fontawesome.com/releases/v5.8.1/css/all.css);
@import url(https://fonts.googleapis.com/css2?family=Lato:ital,wght@0,400;0,700;0,900;1,400;1,700&display=swap);
@import url(https://fonts.googleapis.com/css2?family=Tajawal:wght@500;700&display=swap);

html, body {
    color: #5d656d !important;
}

/***************************** dots loader **************************/
.dotsLoader {
	margin: 0 auto 0;
	text-align: center;
	position: absolute;
	width: 100%;
	opacity: 0;
  }
  .dotsLoader div {
	width: 10px;
	height: 10px;
	background-color: #333;
	border-radius: 100%;
	display: inline-block;
	-webkit-animation: sk-bouncedelay 1.4s infinite ease-in-out both;
	animation: sk-bouncedelay 1.4s infinite ease-in-out both;
  }
  .dotsLoader .bounce1 {
	-webkit-animation-delay: -0.32s;
	animation-delay: -0.32s;
  }
  .dotsLoader .bounce2 {
	-webkit-animation-delay: -0.16s;
	animation-delay: -0.16s;
  }
  @-webkit-keyframes sk-bouncedelay {
	0%, 80%, 100% {
	  -webkit-transform: scale(0);
	}
	40% {
	  -webkit-transform: scale(1);
	}
  }
  @keyframes sk-bouncedelay {
	0%, 80%, 100% {
	  -webkit-transform: scale(0);
	  transform: scale(0);
	}
	40% {
	  -webkit-transform: scale(1);
	  transform: scale(1);
	}
  }

  .dotsSlideDown {
	-webkit-animation: slideDown 0.5s ease-in-out forwards;
	animation: slideDown 0.5s ease-in-out forwards;
  }
  @keyframes slideDown {
	0% {
	  opacity: 0;
	}
	100% {
	  opacity: 0.7;
	}
  }

  .dotsSlideUp {
	-webkit-animation: slideUp 1s ease-in-out forwards;
	animation: slideUp 1s ease-in-out forwards;
  }
  @keyframes slideUp {
	0% {
	  opacity: 0.7;
	}
	100% {
	  opacity: 0;
	}
  }



  .spin {
	margin: auto;
	height: 100%;
	z-index: 999999;
	display: flex;
	align-items: center;
	justify-content: center;
  }
  .spin .spinnerLogo {
	display: block;
	margin: auto;
  }
  .spin .spinnerDot {
	width: 13px;
	height: 13px;
	background-color: #d31148;
	display: inline-block;
	animation-name: loading;
	animation-duration: 1s;
	animation-timing-function: ease;
	animation-iteration-count: infinite;
	margin-top: 12px;
  }
  .spin .spinnerDot2,
  .spin .spinnerDot3 {
	margin-left: 18px;
  }
  .spin .spinnerDot2 {
	animation-delay: 0.2s;
  }
  .spin .spinnerDot3 {
	animation-delay: 0.4s;
  }
  @keyframes loading {
	10%, 30%, 38% {
	  transform: translate3d(-1px, 0, 0);
	  transform: rotate(5deg);
	}
	25%, 35%, 40% {
	  transform: translate3d(1px, 0, 0);
	  transform: rotate(-5deg);
	}
	40% {
	  transform: translate3d(0px, -8px, 0);
	}
	100% {
	  transform: translate3d(0px, 0px, 0);
	}
  }

  .spinner {
	width: 100%;
	margin-left: 0 !important;
	margin-right: 0 !important;
	text-align: center;
	padding: 150px;
  }
  .spinner img {
	max-width: 75px !important;
  }


  .btn-main-outline {
    background: white;
    border: 1px solid #D31148 !important;
    color: #D31148 !important;
}
